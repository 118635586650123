@media only screen and (max-width: 767px) {
  .customer-account-login {
    .block-customer-login {
      margin-bottom: 40px;

      .note {
        font-size: 14px;
      }

      .control {
        width: 100%;
      }

      .primary {
        margin-bottom: 10px;
        width: 100%;
      }

      .secondary {
        text-align: center;

        .remind {
          margin-top: 6px;
          font-size: 14px;

          &:hover {
            color: #337ab7;
          }
        }
      }
    }

    .block-new-customer {
      margin-bottom: 40px;

      .block-content {
        > p {
          font-size: 14px;
        }

        .actions-toolbar {
          margin-top: 25px;
          text-align: center;

          .primary {
            width: 100%;
          }
        }
      }
    }

    &:after {
      clear: both;
      content: '';
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

}