.message {
  padding: 10px 20px;
  margin: 0 -15px 10px -15px;

  &:first-child {
    margin-top: 10px;
  }
}

.message-success {
  background-color: $light-green;
  color: $dark-green;

  & > div:before {
    font-family: "FontAwesome", sans-serif;
    content: "\f00c";
    display: inline-block;
    margin-right: 15px;
  }
}

.message-warning, .message.empty {
  background-color: $light-yellow;
  color: $brown;

  & > div:before {
    font-family: "FontAwesome", sans-serif;
    content: "\f071";
    display: inline-block;
    margin-right: 15px;
  }
}

.message-error {
  background-color: $light-red;
  color: $custom-red;

  & > div:before {
    font-family: "FontAwesome", sans-serif;
    content: "\f06a";
    display: inline-block;
    margin-right: 15px;
  }
}

div.field-error,
div.mage-error {
  display: none;
  background: $lux-red;
  width: 100%;
  border: none;
  color: white;
  padding: 7px 7px 7px 40px;
  margin-top: 10px;
  font-size: 11px;
  position: relative;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 5px 8px;
    border-color: transparent transparent $lux-red;
    position: absolute;
    top: -5px;
    left: 15px;
    font-family: "FontAwesome", sans-serif;
  }

  &:before {
    content: '\f06a';
    width: 0;
    height: 30px;
    font-family: "FontAwesome", sans-serif;
    left: 15px;
    position: absolute;
    font-size: 20px;
    line-height: 25px;
    transform: translate(0, -50%);
    top: 50%;
    margin-top: 2px;
  }
}

[data-role="password-strength-meter"] {
  display: inline-block;

  #password-strength-meter {
    font-size: 12px;
    margin-top: 8px;
    font-weight: bold;
  }
  #password-strength-meter-label {
    color: white;
    padding: 2px 10px;
    border-radius: 2px;
    background-color: $custom-red;
  }
  &.password-strength-meter-1 {
    #password-strength-meter-label {
      background-color: $custom-red;
    }
  }
  &.password-strength-meter-2 {
    #password-strength-meter-label {
      background-color: $yellow;
    }
  }
  &.password-strength-meter-3 {
    #password-strength-meter-label {
      background-color: $custom-green;
    }
  }
  &.password-strength-meter-4 {
    #password-strength-meter-label {
      background-color: $green;
    }
  }
}