.hp-box-content{
  display: block;
  padding: 25px 0 0 0;
  .head-box{
    text-align: center;
    margin-bottom: 25px;
    padding: 0 25px;
    .title{
      font-size: 16px;
      font-weight: bold;
      color: $custom-red;
      margin: 0 0 17px 0;
      text-transform: uppercase;
      line-height: 1.2;
    }
    .title.gray{
      color: $dark-gray;
    }
    .desc{
      font-size: 1.2em;
      font-weight: lighter;
      color: $dark-gray;
      line-height: 1.2;
    }
  }
  .container{
    .col-xs-12,
    .col-sm-12,
    .col-md-12,
    .col-lg-12{
      padding: 0;
    }
  }
}
.hp-box-content.bg-gray{
  background-color: $light-gray;
}