%custom-container {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: 1170px;
}

@media (min-width: 1600px) {
  %custom-container {
    max-width: 1600px;
  }
}
@media (min-width: 1900px) {
  %custom-container {
    max-width: 1900px;
  }
}
@media (min-width: 768px) {
  %custom-container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  %custom-container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  %custom-container {
    width: 1180px;
  }
}