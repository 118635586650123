.checkout-onepage-success {
  h1 {
    font-size: 39px;
  }
  .columns {
    background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
    background-size: 40px 23px;
  }

  .column.main {
    padding: 50px 0;

    .container {
      background: white;
      max-width: 580px;
      padding: 30px 10px;
    }
  }

  .page-title {
    font-family: 'Playfair Display', serif;
    text-align: center;

    &:before {
      content: '';
      display: block;
      width: 80px;
      height: 80px;
      margin: 0 auto 15px auto;
      background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/checkout/icon_checkmark.png');
      background-size: cover;
    }
  }

  .checkout-success {
    &:before {
      content: '';
      display: block;
      width: 40px;
      margin: 25px auto;
      border-top: 1px solid $gold;
    }

    p {
      text-align: center;
    }

    p:first-child {
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;

      span {
        color: $gold;
      }
    }

    p:nth-child(2) {
      display: block;
      width: 80%;
      margin: 20px auto;
    }
  }

  .primary {
    text-align: center;
    padding: 10px 25px 10px 25px;

    .action.primary.continue {
      display: inline-block;
      color: white;
      background: $gold;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
}