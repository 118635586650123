@media (max-width: 767px) {
  .expand-navbar {
    .container {
      padding: 0;
      .mega-menu,
      .customer-menu {
        list-style: none;
        margin: 40px 0 120px 0;
        padding-left: 0;
        > li {
          &:not(:last-child) {
            margin-bottom: 15px;
          }
          > a {
            text-decoration: none;
            display: block;
            margin-bottom: 10px;
            padding-left: 10px;
            font-size: 15px;
            color: $light-black;
            font-weight: bold;
            .fa {
              float: right;
              padding-right: 10px;
            }
          }
        }
        .mega-menu-item-container {
          display: none;
          .mega-menu-item {
            background-color: $super-light-blue;

            .content {
              padding: 30px 30px 10px 30px;
              .mega-menu-column {
                .subcategory-link {
                  font-size: 14px;
                  font-weight: bold;
                }
                ul {
                  list-style: none;
                  padding-left: 0;
                  li {
                    margin-top: 10px;
                    a {
                      font-size: 14px;
                      color: $lux-gray;
                    }
                    &:last-child {
                      margin-bottom: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}