.mylicenses-customer-index {

  .download {
    cursor: pointer;
    i {
      color: $custom-blue;
      font-size: 16px;
      margin-right: 10px;
    }
  }

  #accept-license-modal {
    .license-key {
      display: none;

      .key {
        display: block;
        margin: auto 0;
        text-align: center;
        font-size: 16px;
      }
    }
  }

}