.cms-no-route {

  #maincontent {
    padding: 0 15px 30px 15px;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    max-width: 1170px;
    box-sizing: border-box;
    color: $dark-gray;
  }

  .columns {
    font-size: 14px;
  }

}