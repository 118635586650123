.iwd-opc-shipping-method {
  #checkout-shipping-method-load {

  }
  .shipping-method {
    background: $method-bg;

    &:not(:first-child) {
      margin-top: 15px;
    }
    .shipping-method-title {
      padding: 20px 20px 20px 55px;
      position: relative;
      img {
        float: right;
        max-width: 50px;
      }
      .iwd-carrier-name:before {
        font-family: 'FontAwesome', sans-serif;
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        text-align: center;
        vertical-align: middle;
        color: white;
        background: white;
        border: 1px solid #cecece;
        border-radius: 0;
        position: absolute;
        left: 15px;
        top: 16px;
      }
      input[type="radio"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      input[type='radio']:checked + .label:before {
        @extend %custom-radio-checked;
      }
      & > img.payment-icon {
        @media screen and (max-width: $screen-sm){
          height: auto;
          max-width: 100px;
          max-height: 35px;
        }
        @media screen and (min-width: $screen-md) and (max-width: $screen-lg){
          max-width: 95px;
          height: auto;
          max-height: 30px;
        }
        float: right;
      }
    }
    .shipping-method-content {
      padding: 0 15px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.15s;
    }
    &._active {
      .shipping-method-content {
        display: block;
        max-height: 800px;
        transition: max-height 1s !important;
      }
    }
  }
  input[type='radio'] {
    opacity: 0;
  }

  .iwd-carrier-name:before {
    @extend %custom-radio;
  }

  input[type='radio']:checked + .iwd-carrier-name:before {
    @extend %custom-radio-checked;
  }

  .iwd-shipping-method-price {
    &:before {
      content: '-';
      margin-left: 5px;
    }
  }
}