.catalog-product-view{
  .fotorama__nav-wrap {
    .fotorama__nav {
      display: inline-block;
      vertical-align: middle;
      .fotorama__nav__shaft {
        .fotorama__thumb-border {
          border: 1px solid $gold;
          background-image: none;
          border-radius: 5px;
        }
        .fotorama__nav__frame--thumb {
          .fotorama__thumb {
            background-color: transparent;
            border: 1px solid $gray;
            border-radius: 5px;
          }
        }
      }
    }
    .fotorama__thumb__arr--left {
      display: none !important;
    }
    .fotorama__thumb__arr--right {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 90px;
      height: 90px;
      border: 1px solid $gray;
      border-radius: 5px;
      margin-left: 3px;

      .fotorama__thumb--icon {
        position: absolute;
        top: 10px;
        bottom: -10px;
        left: 10px;
        right: -2px;
        padding: 0;
        margin: auto;
        width: 20px;
        height: 20px;
      }
    }
  }
}
.fotorama__fullscreen {
  .fotorama--fullscreen {
    z-index: 10000 !important;
  }
}