.express-delivery-info {
  margin-bottom: 20px;
  padding-bottom: 40px;
  background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
  background-size: 40px 23px;

  .main-title {
    display: block;
    padding: 50px 0 30px 0;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    color: $light-black;
  }
  .info-box {
    float: left;
    width: 100%;
    margin-bottom: 25px;
    font-size: 0;
    text-align: center;

    .image-wrapper {
      display: inline-block;
      width: 30%;
      vertical-align: middle;

      > img {
        max-width: 100%;
      }

      .image {
        display: block;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        border-radius: 50%;
        background-color: white;
        border: 1px solid $gold;
        .icon {
          display: block;
          position: relative;
          bottom: 31px;
          right: 12px;
          @include scale(.4);
        }
        &:before {
          display: block;
          position: relative;
          left: 45px;
          width: 20px;
          height: 20px;
          font-size: 14px;
          line-height: 17px;
          color: white;
          border-radius: 50%;
          background-color: $gold;
          font-family: 'Playfair Display', serif;
          font-weight: 400;
        }
      }
    }
    .content {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
      text-align: left;
      margin-left: 10px;
      width: 45%;

      .title,
      .description {
        display: block;
        color: $lux-gray;
      }
      .title {
        font-weight: 600;
        font-size: 12px;
      }
    }
    .description {
      line-height: 1;
    }
  }
  @for $i from 1 to 5 {
    .info-box:nth-child(#{$i}) {
      .image-wrapper {
        .image {
          &:before {
            content: '#{$i}';
          }
        }
      }
    }
  }
  .btn-gold {
    margin-top: 40px;
    padding: 10px 60px;
    letter-spacing: 1px;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}