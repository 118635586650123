@media only screen and (max-width: 767px) {
  body:not(.customer-account-create) {
    .account {

      .fieldset.password {
        display: none;
      }

      .actions-toolbar {

        .primary {
          margin-bottom: 0;

          .action.primary {
            width: 100%;
          }
        }
      }

    }
  }
}