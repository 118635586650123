#category-table-contents {
  position: relative;
  padding-top: 40px;

  .affix {
    position: static;
  }
  .table-content-sidebar {
    background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
    background-size: 50px 30px;
    padding: 15px;
    margin-bottom: 40px;
    .table-content-sidebar-content {
      padding: 20px;
      background-color: white;

      & > a {
        font-size: 13px;
        color: $lux-gray;
        display: inline-block;
        text-decoration: none;
        font-weight: 500;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
      ul, ol {
        margin: 0;
        padding: 0;
        list-style: none;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        li {
          color: $light-black;
          font-size: 13px;
          font-weight: 300;
          line-height: 2.2em;
          position: static;
          display: list-item;
          a {
            color: inherit;
            text-decoration: none;
            position: relative;
            display: inline;
            padding: initial;
            &:hover {
            background-color: rgba(0, 0, 0, 0);
            }
          }
        }
      }
    }
  }
  .table-content-content {
    font-size: 12px;
    line-height: 2em;
    ol {
      padding: 0;
      margin: 0;
      padding-left: 15px;
    }
    ul {
      @extend ul.disc;
      li {
        padding: 0;
      }
    }
    .anchor {
      display: block;
      position: relative;
      top: -180px;
      visibility: hidden;
    }
    h2 + h3 {
      .anchor {
        top: -268px;
      }
    }
    & > * {
      margin-bottom: 20px;
    }
    h1, h2, h3, h4, h5, h6 {
      font-family: 'Playfair Display', serif;
      font-weight: 300;
      margin-top: 0;
    }
    h2 {
      font-size: 26px;
      border-bottom: 1px solid $gray;
      padding-bottom: 20px;
      margin-bottom: 45px;
      &:not(.first) {
        margin-top: 45px;
      }
    }
    h3 {
      font-size: 20px;

      &:not(.first) {
        margin-top: 20px;
        &:before {
          display: block;
          width: 40px;
          content: "";
          border-top: 1px solid $gold;
          margin-bottom: 25px;
        }
      }
    }
  }
}