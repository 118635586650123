.posts-slider.blog-box{
  #blog-posts-slider{
    margin: 0 0 70px 0;
    .item{
      text-align: center;
      .posts-slider-content{
        position: relative;
        width: 100%;
        .bg-img{
          background-size: cover;
          width: 100%;
          height: $consul-m-height;
        }
        .text-box{
          width: 100%;
          height: $consul-tb-m-height;
          background-color: rgba(0,0,0,0.3);
          position: absolute;
          bottom:0;
          left:0;
          padding: 15px 10px;
          color: #FFFFFF;
          text-align: left;
          .title{
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .description{
            font-size: 18px;
            font-weight: lighter;
            color: $lighter-gray;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .owl-controls{
      width: 100%;
      position: absolute;
      top:90px;
      left:0;
      .owl-nav{
        .owl-prev,
        .owl-next{
          position: absolute;
          display: inline-block !important;
          .nav-box{
            color: $lux-gray;
            width: 30px;
            height: 55px;
            text-align: center;
            position: relative;
            //background-color: rgba(255,255,255,0.6);
            > *{
              display: inline-block;
              margin-top: 20px;

            }
          }
        }
        .owl-prev{
          left: 0px;
        }
        .owl-next{
          right: 0px;
        }
      }
      .owl-dots{
        text-align: center;
        position: absolute;
        top: 165px;
        width: 100%;
        display: inline-block !important;
        .owl-dot{
          width: 20px;
          height: 3px;
          //border-radius: 50%;
          background-color: $lux-gray;
          display: inline-block;
          margin-right: 10px;
        }
        .owl-dot.active{
          background-color: $gold;
        }
      }
    }

  }
}