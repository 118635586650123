.checkmarks {
  list-style: none;
  padding-left: 20px;
  position: relative;

  li:before {
    font-family: FontAwesome;
    content: '\f00c';
    font-size: 11px;
    color: $gold;
    position: absolute;
    left: 0;
  }
}