// Google font
@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';
@import 'https://fonts.googleapis.com/css?family=Playfair+Display:400,700';

// Bootstrap
@import "source/bootstrap";

// Font Awesome
@import "source/font-awesome";

// Helpers
@import "source/helpers/variables";
@import "source/helpers/functions";
@import "source/helpers/icons";
@import "source/helpers/badges";
@import "source/helpers/messages";
@import "source/helpers/modal";
@import "source/helpers/aligns";
@import "source/helpers/globals";
@import "source/helpers/tables";
// Sprites
@import "source/sprites/sprite.css";

// Modules
//@import "../../Magento_Newsletter/web/css/source/newsletter";

// Components
@import "source/components/mobile/express_delivery_info-m";
@import "source/components/mobile/text-image-widget-m";
@import "source/components/mobile/breadcrumbs-m";
@import "source/components/mobile/section_title_m";
@import "source/components/mobile/custom_container-m";

// Layout
@import "source/layout/header/mobile/expand-navbar-m";
@import "source/layout/header/mobile/mobile-menu-m";
@import "source/layout/footer/mobile/footer-m";
@import "source/layout/cms_no_route_m";

//Layout - Homepage
@import "source/layout/homepage/mobile/slider_container-m";
@import "../../../../../../code/Madcoders/SlidersSwitcher/view/frontend/web/scss/switcher-m";
@import "../../../../../../code/Madcoders/SlidersSwitcher/view/frontend/web/scss/slider/product_slider-m";

//Buttons
@import "source/helpers/buttons";

//Selects
@import "source/helpers/selects";

//Header
@import "../../Magento_Theme/web/scss/header-m";
@import "../../Magento_Search/web/scss/search-mini-form-m";
@import "../../Magento_Checkout/web/scss/mini-cart-m";
@import "../../../../../../code/Madcoders/Contact/view/frontend/web/scss/contact-mini-m";

//Advanced Sliders Styles
@import "../../../../../../code/Madcoders/AdvSlider/view/frontend/web/scss/icon_slider-m";
@import "../../../../../../code/Madcoders/AdvSlider/view/frontend/web/scss/consul_slider-m";
@import "../../../../../../code/Madcoders/AdvSlider/view/frontend/web/scss/banner_slider-m";

////Banner Slider
//@import "../../../../../../code/Madcoders/Sliders/view/frontend/web/scss/banner-slider-m";

//Category Page
@import "../../Magento_Catalog/web/scss/_category-description-m.scss";
@import "../../Magento_Catalog/web/scss/_category-toolbar-m.scss";
@import "../../Magento_Catalog/web/scss/_category-grid-m.scss";
@import "../../Magento_Catalog/web/scss/_category-table-contents-m.scss";
@import "../../../../../../code/Madcoders/CategoryGroup/view/frontend/web/scss/sub-cat-m";

//Filters
@import "../../Manadev_LayeredNavigation/web/scss/filters-m";

// FAQ widget
//@import "../../../../../../code/Madcoders/FaqWidget/view/frontend/web/scss/faq-m";

//Product Page
@import "../../Magento_Catalog/web/scss/catalog_product_view-m";
//@import "../../../../../../code/Madcoders/Highlights/view/frontend/web/scss/highlight-m";
@import "../../Magento_Catalog/web/scss/product_description-m";
@import "../../Magento_Catalog/web/scss/product_gallery-m";

// Reviews
//@import "../../../../../../code/Madcoders/Review/view/frontend/web/scss/review-m";

// Customer Area
@import "../../../../../../code/Madcoders/Customer/view/frontend/web/scss/customer_m";
//@import "../../Magento_Customer/web/source/logout";
//@import "../../Magento_Customer/web/source/resetpassword_m";

// Blog
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/category/cat_head-m";
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/blog_post_m";
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/blog_sidebar_m";
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/blog_comments_m";
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/pagination-m";

// Blog slider
@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/slider/posts_slider-m";

// Cart Page
@import "../../Magento_Checkout/web/scss/cart-page-m";

// Checkout
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/base/grid-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/layout/header-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/components/step_title-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/components/form-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/components/custom_radio-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/components/checkbox-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/components/connected_box-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/components/validation_advice-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/components/authentication-m";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/layout/shipping-m.scss";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/layout/billing-m.scss";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/layout/shipping_methods-m.scss";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/layout/payment-m.scss";
@import "../../../../../../code/IWD/Opc/view/frontend/web/scss/layout/sidebar-m.scss";

// Success Page
@import "../../Magento_Checkout/web/scss/success-page-m";

// Category Page Blog Posts
//@import "../../../../../../code/Madcoders/Blog/view/frontend/web/scss/catalog/blog_cat_post_m";

// Cms pages
@import "../../Magento_Cms/web/scss/cms_m";
@import "../../../../../../code/Madcoders/Cms/view/frontend/web/scss/cms_page-m";

// Checkmarks Widget
@import "../../../../../../code/Madcoders/CheckmarksWidget/view/frontend/web/scss/checkmarks-m";

// Map Widget
@import "../../../../../../code/Madcoders/MapWidget/view/frontend/web/scss/map-widget-m";

// Important note widget
@import "../../../../../../code/Madcoders/ImportantNoteWidget/view/frontend/web/scss/important_note-m";

// LP Widget
@import "../../../../../../code/Madcoders/LandingPageWidget/view/frontend/web/scss/lp-widget-m";

body {
  font-family: 'Poppins', sans-serif;
  position: relative;
  @include placeholder($lux-gray); // helpers/_functions.scss

  .page-main {
    margin-bottom: -20px;
    min-height: 400px;
  }
}
.serif{
  font-family: 'Playfair Display', serif;
  font-weight: bold;
}


// CSS THAT NEED to BE REFACTORED!

.payment-method-title .payment-icon {
    display: none;
}

.payment-method-title > img {
  display: none;
}

.payment-method-note{
  margin-bottom: $line-height-computed;
}