.checkout-cart-index {
  .page-main {
    border-top: 1px solid #ccc;
  }
}

.checkout-cart-container {
  .page-title-wrapper {
    .page-title {
      font-family: 'Playfair Display', serif;
      font-size: 18px;
      font-weight: lighter;
    }
  }
  .cart-items-count-box {
    font-size: 14px;
    width: 100%;
    .cart-items-count {
      text-decoration: underline;
    }
    .cart-items-price {
      font-size: 16px;
      font-weight: bold;
      float: right;
    }
  }
  .form.form-cart {
    position: relative;
    padding-bottom: 230px;
  }
  #block-shipping {
    display: none;
  }
  .checkout-methods-items {
    margin: 0;
    list-style: none;
    padding: 0;
    .action.primary.checkout {
      width: 100%;
      color: white;
      border: none;
      background-color: $gold;
      font-size: 13px;
      text-transform: uppercase;
      margin: 9px 0 0 0;
      padding: 12px 50px 10px 50px;
      font-weight: 300;
      letter-spacing: 1px;
    }
  }
  .cart.main.actions {
    position: absolute;
    width: 100%;
    bottom: 0;
    .action.update {
      width: 100%;
      height: 45px;
      border: 1px solid $ss-dark-gray;
      color: $ss-dark-gray;
      background-color: transparent;
      font-size: 14px;
      padding: 0;
      i {
        display: none;
      }
      visibility: hidden;
    }
  }
  @media screen and (max-width: 768px) {
    #shopping-cart-table {
      display: block;
      .table-caption {
        display: none;
      }
      thead {
        display: block;
        font-size: 0;
        border-bottom: 1px solid $darker-gray;
      }
      tbody.cart.item {
        display: block;
        position: relative;
        border-bottom: 2px solid #ccc;
        padding: 15px 0;
        .item-info {
          display: block;
          &:after {
            clear: both;
            display: block;
            content: "";
          }
          .col {
            display: inline-block;
          }
          .col.item_img {
            height: 65px;
            text-align: center;
            width: 30%;
            .product-image-photo {
              max-height: 100%;
              max-width: 100%;
            }
            float: left;
          }
          .col.item_name {
            width: 60%;
            padding-left: 20px;
            padding-right: 5px;
            float: left;
            .product-item-details {
              margin-bottom: 10px;
              a {
                color: $ss-dark-gray;
              }
              a:hover {
                text-decoration: none;
                color: $ss-dark-gray;
              }
              > * {
                display: block;
                margin-bottom: 5px;
                font-size: 12px;
              }
              .product-item-manufacturer {
                color: $ss-dark-gray;
                text-transform: uppercase;
                font-weight: bold;
              }
              .product-item-name {
                text-transform: uppercase;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              .product-item-sku {
                display: none;
              }
              .attributes-table {
                margin-top: 10px;
                font-size: 12px;
                .attributes-table__name {
                  color: $lux-gray;
                }
                .attributes-table__value {
                  padding-left: 5px;
                }
                td {
                  padding-top: 2px;
                  padding-bottom: 2px;
                }
              }
            }
          }
          .col.item_remove {
            width: 10%;
            text-align: center;
            float: left;
            .action-edit {
              display: none;
            }
            .action-delete {
              font-size: 22px;
              color: $lux-gray;
              text-decoration: none;
              span {
                display: none;
              }
            }
          }
          .col.price,
          .col.qty,
          .col.subtotal {
            .price-excluding-tax {
              margin: 6px 0;
            }
            width: 33%;
            .cart-col-item-title {
              margin-bottom: 15px;
              color: $ss-dark-gray;
              display: inline-block;
              width: 100%;
              vertical-align: middle;
            }
          }
          .col.price {
            text-align: left;
            float: left;
            margin-bottom: 20px;
            .cart-price {
              font-size: 16px;
              color: $ss-dark-gray;
              font-weight: lighter;
            }
          }
          .col.qty {
            text-align: center;
            position: absolute;
            bottom: 20px;
            left: 33%;
            .field.qty {
              display: inline-block;
              .qty-box{
                display: inline-block;
                max-width: 100%;
                min-width: 100px;
                height: 40px;
                color: $ss-dark-gray;
                font-size: 0;
                .input-text.qty, .qty-btn {
                  display: inline-block;
                  font-size: 21px;
                  width: 33%;
                  height: 100%;
                  text-align: center;
                  vertical-align: middle;
                  padding: 5px;
                }

                .qty-input-wrapper {
                  width: 70px;
                  margin: 0 auto;
                  border: 1px solid #ccc;
                  position: relative;
                }

                .qty-btn {

                  .qty-plus,
                  .qty-minus {
                    position: absolute;
                    color: $gold;
                    line-height: 17px;
                  }

                  i.qty-plus {
                    top: 2px;
                  }

                  i.qty-minus {
                    bottom: 2px;
                  }
                }

                .qty-plus:hover,
                .qty-minus:hover{
                  cursor: pointer;
                }

                .input-text.qty.qty-center{
                  border: none;
                  background-color: transparent;
                  text-align: center;
                  -moz-appearance: textfield;

                  &:focus {
                    outline: none;
                  }

                }
                .input-text.qty::-webkit-inner-spin-button,
                .input-text.qty::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
            }
          }
          .col.subtotal {
            text-align: right;
            float: right;
            .cart-price {
              font-size: 16px;
              color: #000000;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .coupon-box {
    position: relative;
    top: -200px;
    .coupon-title {
      font-family: 'Playfair Display', serif;
      text-align: center;
      font-size: 18px;
      font-weight: lighter;
      margin-bottom: 20px;
    }
    .coupon-hint {
      text-align: center;
      font-size: 13px;
      font-weight: lighter;
      color: $ss-dark-gray;
      padding: 0 5px;
      margin-bottom: 10px;
    }
    #discount-coupon-form {
      .fieldset.coupon {
        width: 100%;
        height: 35px;
        border: 1px dashed #ccc;
        font-size: 0;
        position: relative;
        .control {
          width: 60%;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          #coupon_code {
            width: 100%;
            height: 100%;
            border: none;
            font-size: 15px;
            padding-left: 20px;
            line-height: 34px;

            &:focus {
              outline: none;
            }
          }
        }
        .actions-toolbar {
          position: absolute;
          width: 40px;
          height: 100%;
          right: 0;
          .primary {
            width: 100%;
            height: 100%;
            .action.primary {
              width: 100%;
              height: 100%;
              background-color: $gold;
              color: #FFFFFF;
              font-size: 18px;
              text-transform: uppercase;
              border: none;
              font-weight: lighter;
            }
          }
        }
      }
    }
  }
  #cart-totals {
    text-align: right;
    position: relative;
    top: -85px;
    display: block;
    width: 100%;
    .table-wrapper{
      width: 100%;
    }
    .data.table.totals {
      display: block;
      width: 100%;
    }
    .table-caption {
      display: none;
    }
    tbody {
      font-size: 14px;
      color: #000000;
      width: 100%;
      display: block;
      tr {
        margin-bottom: 10px;
        display: block;
        width: 100%;
        text-align: right;
        text-transform: uppercase;
        color: $lux-gray;

        td {
          color: $ss-dark-gray;
        }
      }
      .mark {
        background-color: transparent;
        padding: 0;
        font-weight: normal;
        display: inline-block;
      }
      .mark:after {
        content: ":";
      }
      .amount {
        font-weight: normal;
        display: inline-block;
      }
      .grand.totals {
        font-size: 16px;

        .mark {
          color: $ss-dark-gray;

          strong {
            font-weight: 500;
          }
        }
      }
    }
  }
  #cart-totals + .checkout.methods.items.checkout-methods-items {
    position: relative;
    top: -85px;
  }

  .checkout-methods-items {
    .action.multicheckout {
      display: none;
    }

    .item:first-child {
      display: none;
    }
  }

  :not(.page-title-wrapper) + .checkout-methods-items {
    .item:first-child {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }

  .continue {
    background: $gray;
    text-decoration: none !important;
    color: black;
    display: block;
    margin: 25px 0 0 0;
    padding: 12px 50px 10px 50px;
    font-size: 13px;
    font-weight: 400;
  }
}
.empty-cart {
  padding-bottom: 40px;
  color: $dark-gray;
  font-size: 14px;
  h1 {
    @extend %page-title;
  }
}