.cms-page-view {
  .page-title-wrapper {
    background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
    background-size: 40px 23px;

    .page-title {
      @extend %custom-container;
      font-family: 'Playfair Display', serif;
      color: black !important;
      margin-top: 0 !important;
      padding: 20px 15px;
    }
  }

  .main {
    .sidebar {
      .label {
        display: block;
        margin-bottom: 10px;
        font-size: 12px;
        letter-spacing: 1px;
        color: $lux-gray;
      }

      ul {
        list-style: none;
        padding-left: 0;
      }

      li {
        &:before {
          font-family: FontAwesome;
          content: '\f105';
          margin-right: 10px;
          color: $lux-gray;
        }

        &:hover {
          &:before {
            color: $gold;
          }

          a {
            color: $gold !important;
          }
        }
      }

      a {
        color: $ss-gray !important;
        text-decoration: none;
      }
    }

    .content {
      h1, h2, h3, h4, h5, h6 {
        font-family: 'Playfair Display', serif;
        color: black;
        &.sans-serif {
          font-weight: lighter;
          font-family: 'Poppins', sans-serif;
        }
        a {
          display: block;
          position: relative;
          top: -180px;
          visibility: hidden;
          line-height: 1.2em;
        }
      }
      ol {
        padding: 0;
        margin: 0;
        padding-left: 15px;
      }
      ul:not(.checkmarks) {
        list-style: none;
        padding-left: 0;

        li:before {
          content: '';
          width: 5px;
          height: 5px;
          background-color: $gold;
          float: left;
          margin-right: 10px;
          border-radius: 50%;
          position: relative;
          top: 9px;
        }
      }

      a.arrow-link {
        padding: 7px 0;
        letter-spacing: 1px;
        color: $gold;
        text-decoration: none;
        &:after {
          font-family: FontAwesome;
          content: '\f105';
          margin-left: 5px;
          color: $gray;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}