.checkout-index-index {
  .page-title-wrapper {
    margin-top: 0;
    background: white;
    border-top: 1px solid $gray;

    .page-title {
      @extend %custom-container;
      font-family: 'Playfair Display', serif;
      display: block;
      margin-top: 0;
      padding: 20px 15px;
      font-size: 42px;
      color: black;
    }
  }

  .page-main {
    padding: 0 !important;
    background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
    background-size: 40px 23px;
  }
}