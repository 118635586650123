.sales-order-history {

  .action.order:before {
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 10px;
    border-left: 1px solid #a6a6a6;
    vertical-align: -1px;
  }

  .order-products-toolbar {
    .toolbar-amount {
      margin-top: 30px;
      font-size: 14px;
    }

    .label.pages-label {
      font-size: 14px;
      color: #333333;
      display: block;
      margin-bottom: 10px;
    }

    .items.pages-items {
      list-style: none;
      padding-left: 0;
      font-size: 14px;

      .item.current {
        color: #333333;
      }
    }
  }

  .limiter {
    font-size: 14px;
    color: #333333;
  }

}

.sales-order-view {

  .page-title {
    font-size: 18px;
  }

  .order-status,
  .order-date {
    font-size: 14px;
    color: $ss-gray;
  }

  .action.reorder {
    font-size: 14px;
  }

  .table-wrapper.order-items {
    margin-top: 15px;
    border: 1px solid #ccc;
    padding: 25px;

    .table-order-items {
      .items-qty {
        list-style: none;
        padding-left: 0;

        .title {
          display: none;
        }
      }
    }

    tbody {
      tr {
        border-bottom: none;
      }
    }

    tfoot {
      background: #f5f5f5;

      tr {
        border-bottom: none;
      }

      th {
        background: #f5f5f5;
        font-weight: normal;
        text-align: right;
        padding-right: 15px;

      }
    }

    .subtotal {
      .amount {
        .price {
          position: relative;
          left: 22px;
        }
      }
    }

    .totals-tax {
      .amount {
        .price {
          position: relative;
          left: 22px;
        }
      }
    }

    .shipping {
      .amount {
        .price {
          position: relative;
          left: 22px;
        }
      }
    }

    .grand_total {
      .price {
        position: relative;
        left: 22px;
      }
    }
  }

  .action.print,
  .order-links,
  .order-title {
    display: none;
  }

  .block-order-details-view {
    margin-top: 40px;

    .box {
      float: left;
      height: 200px;
      width: 50% !important;
    }
  }

}

@media only screen and (max-width: 767px) {
  .sales-order-view {

    .table-order-items {
      display: table;
      width: 100%;

      tfoot {
        td:before {
          display: none;
        }

        th {
          padding-right: 0 !important;
          text-align: center !important;
        }

        tr:first-child {
          margin-top: 20px;
        }

        .subtotal {
          .amount {
            padding-left: 0;
            text-align: center;
            .price {
              left: 0 !important;
            }
          }
        }

        .totals-tax {
          .amount {
            padding-left: 0;
            text-align: center;
            .price {
              left: 0 !important;
            }
          }
        }

        .shipping {
          .amount {
            padding-left: 0;
            text-align: center;
            .price {
              left: 0 !important;
            }
          }
        }

        .grand_total {
          .amount {
            padding-left: 0;
            text-align: center;
          }
          .price {
            left: 0 !important;
          }
        }
      }
    }

  }
}