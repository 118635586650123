#reminder-modal,
#accept-license-modal {
  display: none;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, .4);

  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    border-radius: 5px;

    .info {
      display: block;
      margin-bottom: 40px;
      color: $ss-gray;
      text-align: center;
      font-size: 16px;
    }
  }

  .close {
    float: right;
    padding: 3px 12px;
    font-size: 14px;
    font-weight: bold;
    color: black;
    background-color: #cbcbcb;
  }

  .close:hover,
  .close:focus {
    color: white;
    background-color: $custom-blue;
    text-decoration: none;
    cursor: pointer;
    @include transition(.2s);
  }

  .actions {
    text-align: center;
    .action {
      background-color: $custom-blue;
      color: white;
      border: none;
      font-size: 12px;
      padding: 8.5px 27px;

      &:first-child {
        margin-right: 5px;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}