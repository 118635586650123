.comments-wrapper {
  font-size: 14px;
  margin-bottom: 50px;

  #add-comment-message{
    display: none;
    font-size: 14px;
    margin-bottom: 10px;
    #comment-message-text {
      color: $custom-green;
    }
  }
  #add-comment-message.active{
    display: block;
  }

  .comment-counter {
    border-bottom: 2px solid $lighter-gray;
    margin-bottom: 30px;
    .commmenr-count__count {
      display: inline-block;
      position: relative;
      top: 2px;
      padding: 10px 10px 10px 0;
      border-bottom: 2px solid $gold;
    }
  }
  .comment-input {
    font-size: 0;
    margin-bottom: 30px;

    .input-box{
      position: relative;
    }

    div.mage-error {
      display: none;
      background: $custom-red;
      width: 100%;
      border: none;
      color: white;
      padding: 7px 7px 7px 40px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 11px;
      position: relative;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 5px 8px;
        border-color: transparent transparent $custom-red;
        position: absolute;
        top: -5px;
        left: 15px;
        font-family: "FontAwesome", sans-serif;
      }

      &:before {
        content: '\f06a';
        width: 0;
        height: 30px;
        font-family: "FontAwesome", sans-serif;
        left: 15px;
        position: absolute;
        font-size: 20px;
        line-height: 25px;
        transform: translate(0, -50%);
        top: 50%;
        margin-top: 2px;
      }
    }

    #comment_user_name{
      border: 1px solid #e3e3e3;
      padding: 4px 12px;
      width: 100%;
      margin-bottom: 5px;
    }

    #add-comment-btn:hover{
      cursor: pointer;
    }

    #add-comment-btn {
      color: white;
      background-color: $gold;
      border: none;
      padding: 5px 12px;
      width: 100%;
    }

    .comment-input__avatar {
      width: 15%;
      display: inline-block;
      vertical-align: top;
      padding-right: 20px;
      img {
        max-width: 100%;
      }
    }
    .comment-input__textarea {
      vertical-align: top;
      font-size: 14px;
      width: 85%;
      display: inline-block;
      textarea {
        padding: 10px;
        width: 100%;
        border: 1px solid $gray;
        resize: none;
      }
    }
  }

  .comments-list {
    font-size: 0;
    .comments-list__item{
      margin-bottom: 15px;
    }
    .comments-list__item-avatar {
      width: 15%;
      display: inline-block;
      vertical-align: top;
      padding-right: 20px;
      img {
        max-width: 100%;
      }
    }
    .comments-list__item-container {
      vertical-align: top;
      font-size: 14px;
      width: 85%;
      display: inline-block;
      .item-user {
        margin-bottom: 10px;
        font-size: 12px;

        .item-user__name {
          color: $custom-darker-blue;
        }
        .item-user {
          .item-user__when *{
            color:  $ss-gray;
          }
        }
      }
      .item-content{
        color: $ss-gray;
      }
    }
  }
}