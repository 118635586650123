.important-note {
  float: right;
  width: 150px;
  margin-bottom: 8px;
  margin-left: 15px;
  font-size: 14px;

  .important-note-title {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    color: $dark-gray;
  }

  .important-note-content {
    display: block;
    color: $lux-gray;
    font-style: italic;
    font-weight: 100;
  }
}