.iwd-checkout-payment-method {
  .action.primary.checkout {
    display: none;
  }
  .fieldset {
    min-width: initial;
    .control {
      max-width: 222px;
    }
  }
  .payment-method {
    background: $method-bg;

    &:not(:first-child) {
      margin-top: 15px;
    }
    .payment-method-title {
      padding: 20px 20px 20px 55px;
      position: relative;
      .label:before {
        font-family: 'FontAwesome', sans-serif;
        content: '';
        display: inline-block;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        text-align: center;
        vertical-align: middle;
        color: white;
        background: white;
        border: 1px solid #cecece;
        border-radius: 0;
        position: absolute;
        left: 15px;
        top: 16px;
      }
      input[type="radio"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      input[type='radio']:checked + .label:before {
        @extend %custom-radio-checked;
      }
      & > img.payment-icon {
        @media screen and (max-width: $screen-sm){
          height: auto;
          max-width: 100px;
          max-height: 35px;
        }
        @media screen and (min-width: $screen-md) and (max-width: $screen-lg){
          max-width: 95px;
          height: auto;
          max-height: 30px;
        }
        float: right;
      }
    }
    .payment-method-content {
      padding: 0 15px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.15s;
    }
    &._active {
      .payment-method-content {
        display: block;
        max-height: 800px;
        transition: max-height 1s !important;
      }
    }
  }

  input[type='radio'] {
    opacity: 0;
  }

  .label {
    color: $ss-dark-gray;
  }


  .commerz-info {
    display: none;
  }

  .validation-advice {
    position: relative;
    margin-bottom: 10px;

    &:before {
      top: -5px !important;
    }

    &:after {
      top: 3px !important;
    }
  }
  #payone_creditcard_creditcard_form {
    .field.date {
      .field {
        display: inline-block;
      }
    }
  }
}