.lux-modal {
  display: none;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, .4);
  .modal-content {
    background-color: #fefefe;
    padding: 30px;
    width: 800px;
    max-width: 90%;
    border: 4px solid $gold;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 100px;

    .modal-body {
      color: $lux-gray;
      font-size: 14px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    .modal-close {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      font-size: 0;
      button.close {
        color: $gold;
        text-transform: uppercase;
        background-color: white;
        font-size: 20px;
        line-height: 50px;
        width: 50%;
        font-weight: 300;
        border-color: $gray;
        &:first-child {
          border-width: 1px 1px 0 0;
          border-right: 1px solid $gray;
        }
        &:last-child {
          border-width: 1px 0 0 0;
        }
      }
    }
    //&:before {
    //  content: "";
    //  display: block;
    //  width: 0;
    //  height: 0;
    //  border-style: solid;
    //  border-width: 30px 30px 0 0;
    //  border-color: $gold transparent transparent transparent;
    //  position: absolute;
    //  top: 100%;
    //}
    //&:after {
    //  content: "";
    //  display: block;
    //  width: 0;
    //  height: 0;
    //  border-style: solid;
    //  border-width: 20px 20px 0 0;
    //  border-color: white transparent transparent transparent;
    //  position: absolute;
    //  bottom: -19px;
    //  margin-left: 4px;
    //}
    h1, h2, h3, h4, h5, h6 {
      font-family: 'Playfair Display', serif;
      color: black;
      &.sans-serif {
        font-weight: lighter;
        font-family: 'Poppins', sans-serif;
      }
      a {
        display: block;
        position: relative;
        top: -180px;
        visibility: hidden;
      }
    }

    ol {
      padding: 0;
      margin: 0;
      padding-left: 15px;
    }
    ul:not(.checkmarks) {
      list-style: none;
      padding-left: 0;

      li:before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: $gold;
        float: left;
        margin-right: 10px;
        border-radius: 50%;
        position: relative;
        top: 9px;
      }
    }

    a.arrow-link {
      padding: 7px 0;
      letter-spacing: 1px;
      color: $gold;
      text-decoration: none;
      &:after {
        font-family: FontAwesome;
        content: '\f105';
        margin-left: 5px;
        color: $gray;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
  .modal-header {
    display: block !important;
    margin-bottom: 15px;
    margin-top: 10px;
    .modal-title {
      margin-top: 0;
      font-size: 22px;
      letter-spacing: 2px;
      display: inline-block;
      text-transform: uppercase;
    }
    .close {
      font-size: 30px;
      color: white;
      background-color: #b79f7b;
      border: 0;
      top: -20px;
      right: -20px;
      position: absolute;
      width: 40px;
      line-height: 33px;
      height: 40px;
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

.modal-popup {
  display: none;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, .4);
  &._show {
    display: block;
  }
  .modal-inner-wrap {
    background-color: #fefefe;
    padding: 20px 30px 80px 30px;
    width: 500px;
    max-width: 90%;
    border: 4px solid $gold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modal-header {
      display: block !important;
      margin-bottom: 15px;
      margin-top: 10px;
      .action-close {
        font-size: 30px;
        color: white;
        background-color: #b79f7b;
        border: 0;
        top: -20px;
        right: -20px;
        position: absolute;
        width: 40px;
        line-height: 33px;
        height: 40px;
        &:before {
          display: block;
          content: "x";
        }
        span {
          display: none;
        }
      }
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
    .modal-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      font-size: 0;
      button {
        color: $gold;
        text-transform: uppercase;
        background-color: white;
        font-size: 20px;
        line-height: 50px;
        width: 50%;
        font-weight: 300;
        border-color: $gray;
        &:first-child {
          border-width: 1px 1px 0 0;
          border-right: 1px solid $gray;
        }
        &:last-child {
          border-width: 1px 0 0 0;
        }
      }
    }
  }
  .modal-content {
    font-size: 14px;
    color: $light-black;
  }
}
.checkout-index-index {
  .modal-popup {
    .modal-inner-wrap {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 100px;
      width: auto;
      iframe {
        max-height: 625px;
      }
    }
  }
}