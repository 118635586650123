.page-layout-landingpage {
  .breadcrumbs {
    display: none;
  }
  .column.main {
    & > p {
      margin: 0;
    }
  }
}

.lp-widget {
  background-size: cover;
  background-position: center center;
  padding: 35px 0;
  .lp-content-container, .lp-form-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lp-content-container {
    .small-seo-text {
      color: #b79f7b;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 11px;
      margin-bottom: 5px;
      letter-spacing: 2px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
    }
    .big-seo-text {
      color: white;
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 30px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
      margin-top: 0;
    }
    .links {
      & > div {
        margin-bottom: 15px;
      }
      a {
        color: white !important;
        text-decoration: none;
        font-size: 14px;
        text-transform: uppercase;
        display: block;
        padding-left: 40px;
        position: relative;
        min-height: 30px;
        line-height: 1.2em;

        .checkmark {
          width: 30px;
          height: 30px;
          background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/icon_checkmark.png');
          background-size: cover;
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        span {
          vertical-align: sub;
          text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
        }
        &:hover, &:active {
          color: $gold !important;
          text-decoration: none;
        }
      }
    }
  }
  .lp-form-container {
    background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
    background-size: 50px 30px;
    padding-top: 70px;
    .form-title {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      text-align: center;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      background-color: $light-black;
      color: $gold;
      padding: 15px 0;
    }
    #lp-form {
      position: relative;
      fieldset {
        border: none;
        margin: 0;
        padding: 0;
      }
      #lp-form__select {
        display: none;
      }
      input[type="text"], select, textarea {
        color: $light-black;
      }
      input, select, textarea {
        display: block;
        margin-bottom: 10px;
        width: 100%;
      }
      input, select {
        height: 40px;
      }
      input[type="submit"] {
        margin: 0;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 300;
      }
      input[type="text"], textarea {
        padding: 10px;
        border: 2px solid $gray;
      }
      textarea {
        resize: none;
      }
      .message {
        margin: 0 0 10px 0;
        display: none;
        &.message-success {
          margin: 0;
        }
      }
      .mage-error {
        margin-bottom: 10px;
      }
      .loading-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.5);
        .loader {
          text-align: center;
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}