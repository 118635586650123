.checkout-index-index {
  .connected-box {
    .w {
      float: left;

      &:first-child {
        padding-right: 20px;
      }
    }

    .w-30 {
      width: 30%;
    }

    .w-40 {
      width: 40%;
    }

    .w-50 {
      width: 50%;
    }

    .w-60 {
      width: 60%;
    }

    .w-70 {
      width: 70%;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}