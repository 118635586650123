@media only screen and (max-width: 767px) {
  .customer-account-login,
  .customer-account-create,
  .customer-account-forgotpassword {
    .page-main {
      width: 100%;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
      -webkit-flex-basis: auto;
      flex-basis: auto;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      max-width: 1170px;
      box-sizing: border-box;
    }
    .page-title-wrapper {
      .page-title {
        display: inline-block;
        font-weight: 400;
        font-size: 29px;
        margin-top: 0;
        margin-bottom: 20px;
        color: $custom-red;
      }
    }

    .columns {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      box-sizing: border-box;

      .column.main {
        width: 100%;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
        flex-basis: inherit !important;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
        padding-bottom: 40px;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
        box-sizing: border-box;
      }

      .block-title {
        margin-bottom: 15px;

        strong {
          font-size: 18px !important;
          font-weight: 500 !important;
          color: $dark-gray;
        }
      }
    }
  }

  .customer-account-login,
  .customer-account-forgotpassword,
  .customer-account-logoutsuccess,
  .customer-account-create {
    .page-main {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 32px;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      width: auto;
      max-width: 100%;
      background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
      background-size: 40px 23px;
    }

    .page-title-wrapper {
      margin-top: 0;
      background: white;
      border-top: 1px solid #ccc;

      .page-title {
        font-family: 'Playfair Display', serif;
        display: block;
        font-size: 42px;
        color: black;
        padding: 30px 15px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        width: auto;
        max-width: 1170px;
      }
    }

    .columns {
      padding: 30px 15px;
      margin-left: auto;
      margin-right: auto;
      width: auto;
      max-width: 1170px;
      overflow:hidden
    }

    .block-customer-login,
    .block-new-customer,
    .form-create-account {
      width: 100%;
      padding-bottom: 100%;
      margin-bottom: -100%;
      background: white;

      .block-title {
        border-bottom: none;
        margin-bottom: 5px;
        padding-top: 40px;
        padding-left: 40px;

        strong {
          font-size: 14px !important;
          color: black;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }

      .block-content {
        padding-right: 40px;
        padding-left: 40px;
        color: $lux-gray;
        font-weight: lighter;
      }

      .field:after {
        content: '';
        display: block;
        clear: both;
      }

      .note {
        margin-bottom: 30px !important;
      }

      .label {
        color: $dark-gray;
        font-weight: lighter !important;

        &:after {
          margin: 0 0 0 2px !important;
        }
      }

      .control {
        .input-text {
          height: 38px !important;

          &:focus {
            box-shadow: none !important;
            border-color: $gold;
          }
        }
      }

      .action.primary {
        background: $gold;
        border: none;
        border-radius: 0;
        padding: 10px 50px;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 13px;
        letter-spacing: 1px;

        &:focus {
          text-decoration: none;
        }
      }
    }

    .block-customer-login {
      .field {
        margin: 0;
      }

      .actions-toolbar {
        text-align: right;
      }

      .primary {
        float: right;
        margin-right: 0 !important;
        margin-left: 45px;
      }

      .action.login {
        width: 100%;
        margin-top: 15px;
      }

      .action.remind {
        color: $gold;
        font-size: 12px;
        margin-top: 11px;

        &:hover, &:focus {
          text-decoration: none;
          color: $gold;
        }
      }
    }

    .block-new-customer {
      .actions-toolbar {
        margin-top: 35px !important;
      }
    }
  }

  .account {

    .page-main {
      position: relative;
      padding-top: 41px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .page-title {
      font-family: 'Playfair Display', serif;
      color: $gold;
    }

    .columns {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      box-sizing: border-box;

      &:after {
        clear: both;
        content: '';
        display: block;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }

    // Content
    .column.main {
      flex-basis: inherit !important;
      width: 100%;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      -webkit-flex-basis: 100%;
      flex-basis: 100%;
      padding-bottom: 40px;
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
      box-sizing: border-box;
    }

    // Sidebar
    .sidebar-main {
      -webkit-flex-grow: 1;
      flex-grow: 1;
      -webkit-flex-basis: 100%;
      flex-basis: 100%;
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;

      .block-collapsible-nav {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;
        background: white;

        .block-collapsible-nav-title {
          border-top: 1px solid #ccc;
          cursor: pointer;
          margin-bottom: 0;
          position: relative;
          border-bottom: 1px solid #ccc;
          padding: 10px 40px 10px 15px;
          display: block;
          text-decoration: none;

          strong {
            font-weight: 700;
            line-height: 1.1;
            font-size: 14px;
            margin-top: 2px;
            margin-bottom: 2px;
            vertical-align: middle;
          }
        }

        .custom-angle {
          -webkit-font-smoothing: antialiased;
          font-size: 12px;
          line-height: inherit;
          color: inherit;
          font-family: 'FontAwesome';
          margin: 3px 0 0 0;
          vertical-align: middle;
          display: inline-block;
          font-size: 20px;
          font-weight: normal;
          overflow: hidden;
          text-align: center;
          position: absolute;
          right: 20px;
          top: 2px;
        }

        .custom-angle-down:after {
          content: '\f107';
          @extend .custom-angle;
        }

        .custom-angle-up:after {
          content: '\f106';
          @extend .custom-angle;
        }

        .block-collapsible-nav-content {
          border-bottom: 1px solid #ccc;
          display: none;
          background-color: #f5f5f5;
          padding: 15px 0;

          .items {
            margin: 0;
            padding: 0;
            list-style: none;

            .item {
              font-size: 14px;

              &:first-child {
                margin-top: 0;
              }

              &.current {
                > a,
                > strong {
                  border-color: $gold;
                }

                strong {
                  color: black;
                }
              }

              a,
              strong {
                text-decoration: none;
                border-left: 3px solid transparent;
                color: #575757;
                display: block;
              }

              > strong {
                padding: 6px 18px 6px 15px;
              }
            }
          }
        }
      }
    }

  }
}