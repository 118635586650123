.advanced-slider-widget{
  .icon_slider{
    margin: 0 0 30px 0;
    padding: 0 25px;
    a:hover{
      text-decoration: none;
    }
    .item{
      .second-item{
        margin-top:20px
      }
      text-align: center;
      .icon-slide-content{
        display: inline-block;
        text-align: center;
        a {
          text-decoration: none;
        }
        .base-icon{
          display: block;
          height:70px;
          img{
            max-height: 70px;
          }
        }
        .base-icon-hover{
          display: none;
          height: 70px;
          img{
            max-height: 70px;
          }
        }
        .title{
          font-size: 11px;
          margin-top: 5px;
          color: $lux-gray;
        }
      }
      .icon-slide-content:hover{
        .base-icon{
          display: none;
        }
        .base-icon-hover{
          display: block;
        }
      }
    }

    .owl-controls{
      width: 100%;
      position: absolute;
      top:100px;
      left:0;
      .owl-nav{
        .owl-prev,
        .owl-next{
          position: absolute;
          display: inline-block !important;
          .nav-box{
            color: $lux-gray;
            width: 30px;
            height: 35px;
            text-align: center;
            position: relative;
            > *{
            }
          }
        }
        .owl-prev{
          left: 0px;
        }
        .owl-next{
          right: 0px;
        }
      }
      .owl-dots{
        text-align: center;
        position: absolute;
        top: 120px;
        width: 100%;
        display: inline-block !important;
        .owl-dot{
          width: 20px;
          height: 3px;
          //border-radius: 50%;
          background-color: $lux-gray;
          display: inline-block;
          margin-right: 10px;
        }
        .owl-dot.active{
          background-color: $gold;
        }
      }
    }

  }
}