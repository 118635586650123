@media only screen and (max-width: 767px) {
  .customer-account-create {

    .columns {
      padding-bottom: 0 !important;
    }

    .legend {
      border-bottom: none !important;

      span {
        font-size: 14px !important;
        color: black !important;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500 !important;
      }
    }

    .form-create-account {
      width: 100% !important;
      padding: 40px;
    }

    .fieldset.create.account {
      margin-bottom: 20px;
    }

    .actions-toolbar {
      text-align: center;

      .primary {
        width: 100%;
      }
    }

    .action.back {
      display: none;
    }

    #password-strength-meter {
      font-weight: 500 !important;
    }

  }
}