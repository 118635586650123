@media only screen and (max-width: 767px) {
  .customer-account-forgotpassword {

    .columns, .column.main {
      padding-bottom: 0 !important;
    }

    .field.note {
      font-size: 14px;
      color: $lux-gray;
      font-weight: lighter;
    }

    .form.password.forget {
      background: white;
      padding: 40px;

      .fieldset {
        margin-bottom: 0;
      }
    }

    .actions-toolbar {
      .action.submit.primary {
        width: 100%;
      }
    }

    .label {
      padding-top: 8px;
      color: $dark-gray;
      font-weight: lighter !important;

      &:after {
        margin: 0 0 0 2px !important;
      }
    }

    .control {
      .input-text {
        height: 38px !important;

        &:focus {
          box-shadow: none !important;
          border-color: $gold !important;
        }
      }
    }

    .action.submit {
      background: $gold !important;
      border: none !important;
      border-radius: 0 !important;
      padding: 10px 50px !important;
      text-transform: uppercase;
      font-weight: 300 !important;
      font-size: 13px;
      letter-spacing: 1px;

      &:focus {
        text-decoration: none;
      }
    }

  }
}