.blog-sidebar {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 40px;
  @media screen and (min-width: 768px){
    padding-right: 20px;
  }

  @media screen and (min-width: 992px){
    padding-right: 40px;
  }

  @media screen and (min-width: 1201px){
    padding-right: 80px;
  }

  h3 {
    font-size: 11px;
    font-weight: 600;
    color: $lux-gray;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .blog-sidebar-block {
    margin-bottom: 30px;
    padding-bottom: 15px;

    &:not(:last-child) {
      border-bottom: 1px solid $gray;
    }

    .blog-sidebar-block__content {
      ul.block-recent-posts {
        margin-top: 15px;
        padding: 0;
        list-style: none;
        li {
          a {
            color: $ss-gray;
            font-size: 12px;
            text-decoration: none;
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:before {
            font-family: FontAwesome;
            content: '\f105';
            margin-right: 10px;
            color: $gray;
          }

          &:hover {
            &:before {
              color: $gold;
            }

            a {
              color: $gold;
            }
          }
        }
      }
      ul.block-blog-categories {
        margin-top: 15px;
        padding: 0;
        list-style: none;
        li {
          a {
            color: $ss-gray;
            font-size: 12px;
            text-decoration: none;
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:before {
            font-family: FontAwesome;
            content: '\f105';
            margin-right: 10px;
            color: $gray;
          }

          &:hover {
            &:before {
              color: $gold;
            }

            a {
              color: $gold;
            }
          }
        }
      }
    }
  }

  .blog-tags{
    a{
      background-color: $light-gray;
      padding: 8px 10px 5px 10px;
      color: $ss-gray;
      margin: 3px 2px;
      display: inline-block;
      text-decoration: none;
    }
  }
}