.products-grid {
  margin-bottom: 50px;
}

div.products.wrapper {
  .products {
    padding: 0;
    list-style: none;

    .product-item {
      text-align: center;
      &.odd {
        padding-right: 6px;
      }
      &.even {
        padding-left: 6px;
      }
      .product-item-info {
        padding: 5px 5px 0 5px;
        height: 220px;
        display: block;
        color: inherit;
        text-decoration: none;

        &:after {
          display: block;
          content: "";
          clear: both;
        }
        .product-image-price-container {
          .badges {
            right: 15px;
            font-size: 12px;
          }
          height: 120px;
          .product-item-photo {
            padding: 0;
            .product-image-container {
              display: inline-block;
              height: auto;

              .product-image-wrapper {
                display: block;
                position: relative;
                z-index: 1;
                overflow: hidden;
                .product-image-photo {
                  max-height: 115px;
                  max-width: 100%;
                }
              }
            }
          }
        }
        .product-item-details {
          margin-top: 5px;
          padding: 0;
          font-weight: 300;
          font-size: 13px;

          .product-item-manufacturer {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $lux-gray;
            text-transform: uppercase;
          }
          .product-item-name {
            margin-bottom: 0;
            color: $light-black;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
              color: inherit;
              text-decoration: none;
            }
          }

          .product-item-download {
            font-size: 8px;
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }
    }
  }
  .product-loader-container {
    text-align: center;
    .product-loader {
      padding: 5px 30px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .price-box {
    font-weight: 500;
    display: inline-block;
    font-size: 18px;
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;
    .special-price {
      color: $lux-red;
    }
    .old-price {
      font-size: 11px;
      display: block;
      .price-wrapper {
        text-decoration: line-through;
      }
    }
  }
}