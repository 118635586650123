.checkout-index-index {
  .loading-mask {
    display: none !important;
  }

  .columns {
    @extend %custom-container;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .opc {
    padding: 0 15px 30px 15px;
  }

  .opc-wrapper,
  .opc-sidebar {
    width: 100%;

    .step-title {
      margin-bottom: 20px;
    }
  }

  .opc-wrapper {
    margin-top: 15px;
  }

  .opc-sidebar {
    float: left;
    background: white;
    margin-bottom: 15px;

    &:focus {
      outline: 0;
    }

    &:before {
      content: '';
      display: block;
      clear: both;
    }
  }

  %custom-li {
    list-style: none;
    background: white;
    display: block;
  }

  .iwd-checkout-shipping-address,
  .iwd-opc-shipping-method,
  .iwd-checkout-payment-method {
    @extend %custom-li;
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 15px;
  }

  .iwd-opc-shipping-method,
  .iwd-checkout-payment-method {
    padding: 20px;
  }
}