@media (max-width: 767px) {
  .mobile-menu {
    position: fixed;
    top: 0;
    left: -615px;
    width: 80%;
    height: 100vh;
    z-index: $mobile-menu-z-index;
    overflow-y: scroll;
    background-color: white;

    .mobile-nav-tabs {
      margin: 0 10px;
      font-size: 14px;
      border-bottom: 2px solid $gold;
      > li {
        &:not(.active) {
          > a {
            border: none;
            &:hover,
            &:focus {
              color: $gold;
              background-color: white !important;
            }
          }
        }
        &.active {
          font-weight: bold;
          > a {
            border: none;
            border-bottom: 3px solid $gold;
            &:hover,
            &:focus {
              background-color: white !important;
            }
          }
        }
      }
    }
    .close-mobile-menu {
      float: right;
      position: relative;
      top: 10px;
      font-size: 18px;
    }
  }
}