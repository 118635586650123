.checkout-authentication {
  display: none;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, .4);

  .authentication-wrapper {
    display: block !important;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 65%;
    border-radius: 5px;

    .action-auth-toggle {
      display: none;
    }

    .modal-header {
      position: relative;

      .action-close {
        display: none;
      }

      &:after {
        content: 'X';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        background: $gold;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 4px;
        cursor: pointer;
        color: white;
      }
    }

    .modal-custom {
      .block-title {
        font-family: 'Playfair Display', serif;
        font-size: 25px;
      }

      .label {
        display: block;
        margin-top: 10px;
      }

      .actions-toolbar {
        margin-top: 30px;

        .primary {
          float: left;
          margin-right: 5px;

          .action-login {
            border: none;
            background: $gold;
            color: white;
            font-weight: bold;
            padding: 6px 15px;
            font-size: 14px;
            box-sizing: border-box;
            vertical-align: middle;
            text-transform: uppercase;
          }
        }

        .secondary {
          float: left;

          .action-remind {
            position: relative;
            top: 8px;
            color: $gold;
          }
        }

        &:after {
          content: '';
          display: block;
          clear: both;
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }
}