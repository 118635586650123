.checkout-index-index {
  .payment-method-title, .methods-shipping, .custom-summary {
    .mad-checkbox {
      position: relative;
      margin: 15px 0;
      color: $lux-gray;
      a {
        text-decoration: none;
        color: $gold;
      }
      a:hover {
        color: $gold;
        text-decoration: underline;
      }
      /* Base for label styling */
      [type="checkbox"]:not(:checked),
      [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
      }
      [type="checkbox"]:not(:checked) + label,
      [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 11px;
        line-height: 1.1;
        margin-bottom: 20px;
        display: block;
        min-height: 25px;
      }

      /* checkbox aspect */
      [type="checkbox"]:not(:checked) + label:before,
      [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 25px;
        height: 25px;
        border-radius: 0;
        border: 1px solid #CECECE;
        background-color: #EBEBEB;
      }
      /* checked mark aspect */
      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content: "\f00c";
        position: absolute;
        top: 1px;
        left: 0;
        font-size: 22px;
        line-height: 1.3;
        color: #FFFFFF;
        transition: all .2s;
        background-repeat: no-repeat;
        width: 26px;
        height: 26px;
        font-family: "FontAwesome", sans-serif;
        background-color: $gold;
        border: none;
        padding-left: 2px;
      }
      /* checked mark aspect changes */
      [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        //    transform: scale(0);
      }
      [type="checkbox"]:checked + label:after {
        opacity: 1;
        //    transform: scale(1);
      }
      /* disabled checkbox */
      [type="checkbox"]:disabled:not(:checked) + label:before,
      [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
      [type="checkbox"]:disabled:checked + label:after {
        color: #999;
      }
      [type="checkbox"]:disabled + label {
        color: #aaa;
      }
    }
  }
}