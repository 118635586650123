.opc-sidebar {
  .modal-header {
    display: none;
  }

  .custom-summary {
    padding: 20px 30px 0 30px;
  }

  .shipping-address-summary {
    margin: 15px 0;
    color: $lux-gray;
  }

  .focus-trigger {
    color: $gold;

    &:hover {
      color: $gold;
      cursor: pointer;
    }
  }

  .text-summary {
    display: block;
  }

  .telephone-summary {
    margin-top: 15px;
  }

  .email-summary {
    margin: 15px 0;
  }

  .info {
    padding: 10px;
    font-size: 11px;
    color: $lux-gray;
    background: $method-bg;
  }

  .opc-block-summary {
    margin: 15px;
    padding: 0 15px 15px 15px;
    border: 3px solid $gold;
  }

  .items-in-cart-header {
    color: $lux-gray;
    padding: 10px 0 2px 0;
    letter-spacing: 1px;
    border-bottom: 1px solid $classic-silver;

    .product-label {
      display: block;
      float: left;
      width: 60%;
    }

    .qty-label {
      display: block;
      float: left;
      width: 20%;
      margin-right: 6%;
    }

    .total-label {
      display: block;
      float: left;
      width: 14%;
      text-align: right;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .minicart-items {
    list-style: none;
    padding-left: 0;
  }

  .items-in-cart:focus {
    outline: 0;
  }

  .product-item {
    border-bottom: 1px solid $classic-silver;
    margin: 10px 0;
    padding-bottom: 10px;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .product-image-container,
  .product-item-details,
  .product-item-name-block,
  .product-item-name,
  .details-qty,
  .subtotal {
    float: left;
  }

  .product-item-name {
    width: 100px;
    margin-right: 35px;
    font-weight: lighter;
  }

  .details-qty {
    margin-right: 45px;
  }

  .table-totals {
    width: 100%;
    color: $ss-dark-gray;
  }

  tr {
    font-size: 14px;
    text-align: left;
  }

  .totals {
    color: $lux-gray;

    .mark {
      font-weight: lighter;
    }

    .price {
      color: $ss-dark-gray;
    }
  }

  .grand.totals {
    font-size: 16px;
    border-top: 1px solid $classic-silver;

    .mark {
      padding: 15px 0;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    strong {
      font-size: 14px;
      font-weight: 500;
      color: $ss-dark-gray;
    }

    .price {
      font-weight: bold;
    }

    th {
      padding: 20px 0;
    }
  }

  th {
    padding: 10px 0;
    background: none;
  }

  td {
    text-align: right;
  }

  .checkout-submit-btn {
    display: inline-block;
    position: relative;
    width: 100%;
    min-height: 45px;
    padding: 8px 18px;
    color: white;
    text-align: center;
    letter-spacing: 1px;
    border: none;
    background: $gold;
    @extend .btn-gold-grad;

    &:focus {
      outline: 0;
    }
  }
}