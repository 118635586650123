.cms-page-view {
  #maincontent {
    font-size: 14px;
    color: $dark-gray;
    line-height: 1.7;
    margin-bottom: 60px;

    .page-title {
      margin-bottom: 25px;
      margin-top: 25px;
      font-weight: 300;
      line-height: 1.1;
      font-size: 26px;
      color: #696969;
    }
  }
}