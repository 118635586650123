.text-image-widgets {
  margin-bottom: 40px;
  .text-image-widget-h2 {
    color: $dark-gray;
    font-size: 19px;
    font-weight: bold;
  }
  .subtitle {
    display: block;
    margin-top: 20px;
    color: $ss-gray;
    font-size: 16px;
    font-weight: lighter;
  }
  .text-image-widget {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.text-image-widget-container {
  background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
  background-size: 40px 23px;
  padding-bottom: 60px;

  > div:not(:last-child) {
    margin-bottom: 20px;
  }

  .text-image-widget-title {
    display: block;
    padding: 50px 0;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    color: $light-black;
  }
  .text-image-widget {
    background-color: white;

    .title-box {
      .title {
        font-size: 14px;
        font-weight: bold;
        color: $dark-gray;
      }
    }
    .image-box {
      padding: 0;
      img {
        max-width: 100%;
      }
    }
    .content-box {
      padding: 0;
      .content {
        font-size: 12px;
        color: $light-black;
        padding: 15px;

        .btn {
          margin-top: 10px;
          padding: 15px 30px;
          line-height: 11px;
        }
        .category {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 600;
          color: $lux-gray;
        }

        .title {
          font-size: 18px;
          margin-top: 0;
          font-weight: 400;
          letter-spacing: 0.2px;
          line-height: 1.4em;
        }

        hr {
          width: 35px;
          margin-left: 0;
          border-top: 1px solid $gold;
          margin-top: 17px;
          margin-bottom: 20px;
        }

        ul,
        ol {
          a {
            color: $ss-gray;
          }
          li:not(:last-child) {
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}