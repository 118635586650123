#pagination-form {
  margin: 30px 0 80px;

  .page-navigation {
    color: $gold;
    background: none;
    border: none;
    letter-spacing: 1px;

    &:focus {
      outline: 0;
    }
  }

  #prev-page:before {
    font-family: FontAwesome;
    content: '\f104';
    margin-right: 5px;
    color: $gray;
  }

  #next-page:after {
    font-family: FontAwesome;
    content: '\f105';
    margin-left: 5px;
    color: $gray;
  }
}