div.minicart-wrapper {
  vertical-align: middle;
  display: inline-block;
  &:after {
    display: block;
    content: "";
    clear: both;
  }
  a.showcart {
    position: relative;
    display: block;
    div.img {
      width: 24px;
      height: 24px;
      background-image: url(../images/header/mini-cart.png);
      background-size: cover;
      margin: 0 auto;
      margin-bottom: 5px;
    }
    span.counter {
      padding: 0 5px;
      background: $gold;
      border-radius: 1em;
      font-size: 11px;
      color: white;
      position: absolute;
      bottom: 16px;
      right: 5px;
      border: 2px solid white;
      span.counter-number {
        line-height: 1.5em;
        position: relative;
        top: 1px;
        min-width: 6px;
        display: inline-block;
      }
      span.counter-label{
        display: none;
      }
    }
  }
}

div.minicart-wrapper {
  .ui-dialog.ui-widget.ui-widget-content{
    display: none;
    #minicart-content-wrapper {
      position: absolute;
      width: 330px;
      max-width: 100%;
      padding: 5px;
      border: 1px solid $light-gray;
      background-color: #FFFFFF;
      right: 0;
      z-index: 100;
      -webkit-box-shadow: 0  0 10px rgba(0,0,0,0.6);
      -moz-box-shadow: 0  0 10px rgba(0,0,0,0.6);
      box-shadow: 0  0 10px rgba(0,0,0,0.6);

      a:focus {
        color: inherit;
        outline: none;
        text-decoration: none;
      }
      .arrow {
        text-align: center;
        color: $gold;
        background-color: $light-gray;
        height: 30px;
        font-size: 21px;
        display: none;
      }
      .arrow.arrow-down{
      }
      .arrow:hover{
        cursor: pointer;
      }

      .block-content {
        .subtotal {
          height: 50px;
          padding: 15px 0;
          border-bottom: 1px solid $light-gray;
          border-top: 1px solid $light-gray;
          font-size: 13px;
          font-weight: 500;
          .label {
            display: inline-block;
            float: left;
          }
          .label:after {
            content: ":";
          }
          .amount.price-container {
            display: inline-block;
            float: right;
          }
        }
        .actions {
          display: block;
          margin: 10px 0;
          font-size: 0;
          .primary,
          .secondary{
            display: inline-block;
            height: 36px;
            font-size: 12px;
            vertical-align: top;
            text-transform: uppercase;
            .action.primary.checkout{
              background-color: $gold;
              color: #FFFFFF;
              border: none;
              border-radius: 0;
              width: 95%;
              margin-right: 5%;
            }
            .action.viewcart{
              background-color: $lux-gray;
              color: #FFFFFF;
              border: none;
              border-radius: 0;
              width: 100%;
              display: inline-block;
              text-align: center;
              height: 100%;
              padding: 9px 0;
            }
            .action.viewcart:hover{
              cursor: pointer;
              color: #FFFFFF;
              text-decoration: none;
            }

          }
          .primary{
            width: 45%;
          }
          .secondary{
            width: 55%;
          }
        }

      }
      .minicart-items-wrapper.overflowed{
        max-height: 300px;
        overflow: hidden;
      }
      #mini-cart.minicart-items {
        list-style: none;
        margin: 0;
        padding: 0;
        height: auto !important;
        position: relative;
        .item.product.product-item{
          height: 100px;
          display: block;
          overflow: hidden;
          width: 100%;
          padding: 10px 0;
          border-bottom: 1px solid $light-gray;
          .product-item-photo{
            display: inline-block;
            width: 85px;
            vertical-align: top;
            .product-image-photo{
              width: auto;
              height: auto;
              max-width: 100%;
            }
          }
          .product-item-details{
            display: inline-block;
            width: 210px;
            vertical-align: top;
            margin-left: 5px;
            text-align: left;
            .product-item-manufacturer{
              font-size: 12px;
              font-weight: bold;
              color: $ss-dark-gray;
              margin-bottom: 3px;
            }
            .product-item-name{
              font-size: 11px;
              max-height: 30px;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              > a{
                font-weight: normal;
                color: $ss-dark-gray;
              }
              > a:hover{
                color: $ss-dark-gray;
              }
            }
            .product-item-pricing{
              margin-top: 7px;
              display: block;
              > * {
                display: inline-block;
                font-size: 18px;
                color: $ss-dark-gray;
                margin-right: 3px;
              }
              .item-qty-x{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  #minicart-content-wrapper.scroll-active{
    .arrow{
      display: block !important;
    }
    .arrow.disabled{
      color: $ss-dark-gray;
    }
    .arrow.disabled:hover{
      cursor: default;
    }
    .arrow.arrow-down{
      margin-bottom: 10px;
    }
  }


}

/* Checkout authentication modal dialog - hidden because it doesn't have styles  */
//.modals-wrapper {
//  display: none;
//}