@media only screen and (max-width: 767px) {
  .account,
  .customer-account-login,
  .customer-account-create,
  .customer-account-forgotpassword {
    .block-title {
      margin-bottom: 25px;
      padding-bottom: 10px;
      border-bottom: 1px solid #c6c6c6;

      strong {
        font-size: 22px;
        font-weight: 300;
      }
    }

    .block-content {
      .box {
        margin-bottom: 20px;

        .box-title {
          display: inline-block;
          margin: 0 0 10px;
          font-weight: 500;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        .box-content {
          line-height: 24px;
          font-size: 14px;
          color: $ss-gray;
        }

        .box-actions {
          margin-top: 5px;

          .action {
            color: #1979c3;
            font-size: 14px;
          }
        }
      }
    }

    .empty {
      font-size: 16px;
      color: $ss-gray;
    }

    .legend {
      border-bottom: none !important;

      span {
        font-size: 14px !important;
        color: black !important;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500 !important;
      }
    }
  }
}