.column.main {
  a {
    text-decoration: underline;
    color: inherit;
  }
}
ul.disc  {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding-left: 16px;
  }

  li:before {
    content: "•";
    padding-right: 8px;
    color: $gold;
    font-size: 16px;
    line-height: 14px;
  }
}
%page-title {
  font-family: 'Playfair Display', serif;
  color: $light-black;
  margin-bottom: 20px;
  margin-top: 20px;
}
.page-title {
  @extend %page-title;
}
.cms-no-route, .checkout-cart-index {
  .page-title-wrapper {
    margin-top: 0;
    background: white;

    .page-title {
      font-family: 'Playfair Display', serif;
      display: block;
      font-size: 42px;
      color: black;
      padding: 30px 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      width: auto;
      max-width: 1170px;
    }
  }
}
