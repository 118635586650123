.homepage-sliders-switcher-content,
.product-sliders-switcher-content {
  margin: 25px 0 10px 0;
  .switcher-content {
    font-size: 0;
    width: 100%;
    border-bottom: 2px solid $super-light-blue;
    //margin-bottom: 17px;
    #select-switcher-item-btn {
      height: 40px;
      padding: 10px 0;
      #active-switch-item {
        color: #000000;
        font-size: 14px;
        line-height: 1.0;
        text-transform: uppercase;
        font-weight: bold;
      }
      > i {
        font-size: 18px;
        color: $lux-gray;
        float: right;
      }
    }
    #select-switcher-item-btn:hover {
      cursor: pointer;
    }
    #switcher-items-box {
      display: none;
    }
    #switcher-items-box.active {
      display: block;
    }
    .switcher-item {
      display: block;
      border-bottom: 2px solid #FFFFFF;
      padding: 18px 0;
      color: $dark-gray;
      font-size: 14px;
      line-height: 1.0;
      text-transform: uppercase;
      font-weight: lighter;
    }
    .switcher-item.active {
      color: #000000;
    }
    .switcher-item:hover {
      color: #000000;
      cursor: pointer;
      font-weight: bold;
      border-bottom: 3px solid $gold;
    }
  }
  .switcher-box {
    display: none;
  }
  .switcher-box.active {
    display: block;
  }
  .ajax-loader {
    text-align: center;
    > img {
      max-width: 100%;
    }
  }

  #show-more-products-switch {
    font-size: 14px;
    font-weight: bold;
    border: 1px solid $gold;
    color: $gold;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    padding: 14px 0;
    display: none;
  }
  #show-more-products-switch.active {
    display: block;
  }

  .switcher-border-bottom {
    display: none;
  }
}

.product-sliders-switcher-content {
  margin: 0;
  padding: 25px 0 60px;
}