.customer-account-logoutsuccess {
  .page-main {
    @extend %custom-container;
  }

  .page-title {
    font-family: 'Playfair Display', serif;
    margin-bottom: 20px;
  }

  .column.main {
    color: $ss-dark-gray;
    font-size: 14px;
    padding-bottom: 100px;
  }
}