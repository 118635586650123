.product-slide-content {
  text-align: center;
  .product-image-wrapper {
    display: block;
    height: 100px;
    width: 100%;
    margin: 10px 0;
    text-align: center;
    .product-image-photo {
      max-height: 100%;
      width: auto !important;
      margin: auto;
    }
  }

  .manufacturer {
    text-align: center;
    //font-weight: bold;
    font-size: 11px;
    color: $lux-gray;
  }
  .product-name {
    text-align: center;
    font-size: 10px;
    //height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $ss-dark-gray;
    font-weight: lighter;
  }

  .product-reviews-summary {
    letter-spacing: 3px;
    margin: 5px 0 0 0;
    display: block;
    text-align: center;
    font-size: 16px;
    .review-stars-gray {
      color: $gray;
      position: relative;
      display: inline-block;

      .review-stars-color {
        color: #f2a53c;
        position: absolute;
        top: 0;
        overflow: hidden;
      }
    }
    .review-count {
      display: none;
    }

  }

  .product-item-download {
    display: block;
    text-align: center;
    font-size: 8px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
    color: $ss-dark-gray;
    .fa-download {
      color: $custom-green;
      font-size: 12px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
  .price-box {
    font-size: 16px;
    margin: 5px 0;
    .price.currency {
      font-size: 16px;
    }
  }

  .price-box {

    display: inline-block;
    text-align: center;
    //font-style: italic;
    font-weight: 600;

    &:after {
      display: block;
      content: "";
      clear: both;
    }
    .price.after-comma {
      font-size: 0.75em;
    }
    .special-price {
      color: $lux-red;

      .tax .price {
          position: relative;
          top: -2px;
          margin-left: 5px;
      }
    }
    .price-final_price {
      font-weight: 600;
    }
    .old-price {
      .price-wrapper {
        font-weight: 500;
        color: $ss-dark-gray;
        font-size: 11px;
        vertical-align: super;
        text-decoration: line-through;
        .before-comma {

        }
        //.after-comma {
        //  sup {
        //    text-decoration: line-through;
        //  }
        //}
      }
    }
  }
}
.switcher-slider-content-mobile {
  font-size: 0;
  .item {
    width: 49%;
    height: 205px;
    vertical-align: top;
    margin-bottom: 17px;
    border: 1px solid $gray;
    position: relative;
    &:nth-child(odd) {
      margin-right: 1%;
    }
    &:nth-child(even) {
      margin-left: 1%;
    }
    .price-box {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
    }
  }
  .item {
    display: none;
  }
  .item.mobile-active {
    display: inline-block;
  }
}


.switcher-slider-content-screen {
  #news-slider,
  #best-seller-slider,
  #best-rank-slider,
  #sale-slider,
  #recently-viewed-slider{
    .owl-controls {
      display: none;
    }
  }
}