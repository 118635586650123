.sub-cat-category-view {
  background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
  background-size: 40px 23px;
  padding-bottom: 15px;
  margin-bottom: 8px;
  .sub-cat-block-box {
    .sub-cat-item-col {
      padding: 0;
      display: none;
      &.active{
        display: block;
        &.sub-overlay{
          .sub-cat-item{
            position: relative;
            &:before{
              content: " ";
              position: absolute;
              width: 100%;
              height: 100%;
              background: -moz-linear-gradient(270deg, rgba(153,218,255,0) 0%, rgba(255,255,255,1) 100%);
              background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(153,218,255,0)), color-stop(100%, rgba(255,255,255,1)));
              background: -webkit-linear-gradient(270deg, rgba(153,218,255,0) 0%, rgba(255,255,255,1) 100%);
              background: -o-linear-gradient(270deg, rgba(153,218,255,0) 0%, rgba(255,255,255,1) 100%);
              background: -ms-linear-gradient(270deg, rgba(153,218,255,0) 0%, rgba(255,255,255,1) 100%);
              background: linear-gradient(180deg, rgba(153,218,255,0) 0%, rgba(255,255,255,1) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99DAFF', endColorstr='#FFFFFF',GradientType=0 );
              z-index: 999;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
    .sub-cat-link{
      color: initial;
      text-decoration: none;
    }
    .sub-cat-link:hover{
      color: initial;
      text-decoration: none;
    }
    .sub-cat-main-title {
      text-align: center;
      color: $ss-dark-gray;
      margin: 17px 0;
      font-size: 17px;
      font-weight: bold;
    }

    .sub-cat-item {
      margin-top: 15px;
      background-size: 30%;
      background-repeat: no-repeat;
      background-color: white;
      background-position: left center;
      text-align: left;
      height: 63px;
      font-weight: 300;
      &:hover {
        .sub-cat-link {
          color: $gold;
          border: 2px solid $gold;
          .sub-cat-item-title {
            left: -1px;
          }
        }
      }
      .sub-cat-link {
        padding-left: 30%;
        padding-right: 10px;
        display: block;
        height: 100%;
        color: $light-black;
        border: 1px solid $super-light-blue;

        .sub-cat-item-title {
          font-size: 14px;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: uppercase;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          line-height: 17px;

          &:after {
            font-family: FontAwesome;
            content: "\f105";
            color: $gold;
            vertical-align: middle;
            font-size: 20px;
            margin-left: 3px;
          }
        }

        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}