@media only screen and (max-width: 767px) {
  .customer-address-index {

    .block-addresses-default {
      margin-bottom: 40px;
    }

    .block-addresses-list {
      margin-bottom: 40px;

      .block-content {
        font-size: 14px;
        color: $ss-gray;
      }
    }

  }
}