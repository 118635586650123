@media only screen and (max-width: 767px) {
  .account,
  .customer-account-login,
  .customer-account-create,
  .customer-account-forgotpassword,
  .customer-account-createpassword {
    .fieldset {
      border: 0;
      margin: 0 0 40px;
      padding: 0;
      letter-spacing: -.31em;

      > .legend {
        margin: 0 0 20px;
        padding: 0 0 10px;
        width: 100%;
        box-sizing: border-box;
        float: left;
        font-weight: 300;
        line-height: 1.2;
        font-size: 18px;
        border-bottom: 1px solid #c6c6c6;

        span {
          font-size: 22px;
          font-weight: 300;
          margin-right: 5px;
        }
      }

      > * {
        letter-spacing: normal;
      }

      .field {
        margin: 0 0 20px;

        > .label {
          font-weight: 600;
          margin: 0 0 8px;
          display: inline-block;
          font-size: 14px;
        }

        input:not([type='checkbox']) {
          background: #fff;
          background-clip: padding-box;
          border: 1px solid #c2c2c2;
          border-radius: 1px;
          font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica, Arial, sans-serif';
          font-size: 14px;
          height: 32px;
          line-height: 1.42857143;
          padding: 0 9px;
          vertical-align: baseline;
          width: 100%;
          box-sizing: border-box;

          &:focus {
            outline: 0;
            box-shadow: 0 0 3px 1px $custom-blue;
          }
        }

        &.required {
          > .label {
            &:after {
              content: '*';
              color: $custom-red;
              font-size: 12px;
              margin: 0 0 0 5px;
            }
          }
        }

        &.choice {
          margin-bottom: 0;

          input[type='checkbox'] {
            margin: 2px 5px 0 0;
          }

          .label {
            font-weight: normal;
          }
        }
      }

      .label {
        padding-top: 8px;
        color: $dark-gray;
        font-weight: lighter !important;

        &:after {
          margin: 0 0 0 2px !important;
        }
      }

      .control {
        .input-text {
          height: 38px !important;

          &:focus {
            box-shadow: none !important;
            border-color: $gold !important;
          }
        }
      }

      #password-strength-meter {
        font-weight: 500 !important;
      }
    }
  }
}