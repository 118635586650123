@media only screen and (max-width: 767px) {
  .account,
  .customer-account-login,
  .customer-account-create {
    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .table-caption {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      .col {
        &.actions {
          font-size: 14px;
        }
      }

      &:not(last-child) {
        margin-bottom: 20px;
      }
    }

    td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 50%;
      font-size: 14px;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      color: black;
      font-size: 14px;
      font-weight: 700;
    }

    /*
    Label the data
    */
    td:nth-of-type(1):before { content: "Order #"; }
    td:nth-of-type(2):before { content: "Date"; }
    td:nth-of-type(3):before { content: "Ship To"; }
    td:nth-of-type(4):before { content: "Order Total"; }
    td:nth-of-type(5):before { content: "Status"; }
    td:nth-of-type(6):before { content: "Action"; }
  }
}