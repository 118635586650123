.checkout-index-index {
  // Spacing between form fields
  .control {
    margin-bottom: 10px;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  .label {
    color: $darker-gray;
  }

  .input-text {
    width: 100%;
    height: 35px;
    padding: 0 15px;
    border: 1px solid rgb(238, 238, 238);
  }

  .input-text:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid $gold;
  }

  %red-star-after-label {
    content: '*';
    color: $custom-red;
  }
}