.map-widget {
  padding-top: 40px;
  padding-bottom: 40px;

  .section-title {
    margin-bottom: 40px;
  }
  #map1 {
    margin-bottom: 20px;
  }
  #map1, #map2 {
    height: 350px;
  }
  .map-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .map-col {
    position: relative;
  }
  .map-address {
    position: absolute;
    bottom: 25px;
    left: 35px;
    background-color: white;
    border-bottom: 2px solid $gold;
    padding: 15px;
    color: $lux-gray;
    .map-address__street {
      margin: 0;
    }
    .image-telephone {
      .consultant-image {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-size: cover;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: 5px;
      }
      .map-telephone {
        vertical-align: middle;
        font-size: 14px;
        color: $light-black;
        a {
          color: inherit !important;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}