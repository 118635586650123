div.block.block-search {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
  #search_mini_form {
    font-size: 0;
    .field.search {
      max-height: 36px;
      display: inline-block;
      margin-right: -32px;
      width: 100%;
      input#search {
        font-size: 12px;
        background-color: white;
        border: 2px solid $gray;
        color: $dark-gray;
        height: 31px;
        width: 100%;
        padding: 2px 27px 0 15px;
        vertical-align: top;
        line-height: 31px;
        &::-webkit-input-placeholder {
          color: $dark-gray;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $dark-gray;
        }
        &::-moz-placeholder {  /* Firefox 19+ */
          color: $dark-gray;
        }
        &:-ms-input-placeholder {
          color: $dark-gray;
        }

        &:focus {
          outline: 0;
        }
      }
    }
    .actions {
      font-size: initial;
      display: inline-block;
      vertical-align: top;
      button {
        height: 31px;
        width: 32px;
        padding: 0 11px;
      }
    }
  }
}