.product-attributes-reviews-wrapper {
  background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/luxustrade/blue-pattern.png');
  background-size: 50px 30px;
  padding: 45px 0 60px;

  .additional-attributes-wrapper {
    #product-attribute-specs-table {
      width: 100%;
      .table-caption {
        text-align: left;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 2px;
        padding-bottom: 20px;
      }
      tr {
        font-size: 13px;
        td {
          text-align: left;
          padding: 8px;
          border: none;
          &.label {
            color: $lux-dark-blue;
            font-weight: 400;
            width: 30%;
          }
          &.data {
            color: $light-black;
            font-weight: 500;
          }
        }
        &:nth-child(even) {
          background-color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}

.product-description {
  padding: 50px 0;

  .description-title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 2px;
    padding-bottom: 20px;
  }
  .description {
    line-height: 22px;
  }
  img {
    max-width: 100%;
  }
}