.iwd-checkout-shipping-address {
  // Custom tabs (added to shipping.html)
  .tab {
    display: block;
    float: left;
    width: 50%;
    padding: 20px 0;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
  }

  .tabs:after {
    content: '';
    display: block;
    clear: both;
  }

  .custom-active-tab {
    width: 55%;
    background: white;
  }

  .open-modal-tab {
    width: 45%;
    background: #ebebeb;
    color: #878787;
    float: right;
    &:hover {
      color: $gold;
      cursor: pointer;
    }
  }

  .message.warning {
    display: none;
  }

  .label {
    display: block;
    clear: both;
  }

  // Form login
  .form-login {
    .note {
      display: block;
      margin-top: 8px;
      color: $lux-gray;
    }

    .primary,
    .secondary {
      float: left;
      margin-bottom: 15px;
    }

    .secondary {
      padding-top: 6px;
    }

    .login {
      padding: 8px 30px 6px 30px;
      color: white;
      text-transform: uppercase;
      background: $gold;
      border: none;
    }

    .remind {
      margin-left: 10px;
      color: $gold;
    }
  }

  .label[for='customer-email']:after {
    @extend %red-star-after-label;
    margin-left: -3px;
  }

  #customer-email {
    width: 100%;
  }

  // Form shipping address
  .iwd-checkout-step-shipping {
    padding: 20px 30px;
  }

  .iwd-form-shipping-address .mage-error {
    white-space: nowrap;
  }

  .field[name='shippingAddress.firstname'],
  .field[name='shippingAddress.lastname'] {
    float: left;
    width: 47%;
  }

  .field[name='shippingAddress.city'] {
    float: right;
    width: 60%;
  }

  .field[name='shippingAddress.postcode'] {
    float: left;
    width: 34%;
  }

  .field[name='shippingAddress.firstname'],
  .field[name='shippingAddress.postcode'] {
    margin-right: 6%;
  }

  .field[name='shippingAddress.firstname'],
  .field[name='shippingAddress.lastname'],
  .field[name='shippingAddress.city'],
  .field[name='shippingAddress.postcode'],
  .field[name='shippingAddress.telephone'] {
    .label:after {
      @extend %red-star-after-label;
      margin-left: -3px;
    }
  }

  .field.street {
    .label span:after {
      @extend %red-star-after-label;
    }
  }

  // Hidden region_id and country_id
  .field[name='shippingAddress.region_id'],
  .field[name='shippingAddress.country_id'] {
    display: none;
  }

  .input-text[name='street[1]'] {
    display: none;
  }

  .field-tooltip-content {
    display: none;
  }

  .mad-checkbox {
    margin-top: 25px;
    margin-bottom: 0;
  }

  .agree-title {
    padding-top: 8px;
    letter-spacing: 1px;
  }

  // Shipping address for logged in
  .iwd-shipping-address-item {
    input[type='radio'] {
      display: none;
    }
  }

  .action-show-popup {
    display: none;
  }
}