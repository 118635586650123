.checkout-index-index {
  %custom-radio {
    font-family: 'FontAwesome', sans-serif;
    content: '';
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
    color: white;
    background: white;
    border: 1px solid #cecece;
    border-radius: 0;
  }

  %custom-radio-checked {
    content: '\f00c';
    font-size: 20px;
    background: $gold;
    border: none;
  }
}