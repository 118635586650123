.block.filter {
  hr {
    margin: 15px 0;
  }
  .filter-title {
    color: $light-black;
    font-size: 20px;
    font-weight: 300;
    padding-right: 0;
    margin-bottom: 10px;
    padding-left: 15px;
  }
  .filter-content {
    ul.filter-options {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      &:after {
        display: block;
        clear: both;
        content: "";
      }

      .filter-option {
        &:nth-child(odd) {
          padding-right: 7px;
        }
        &:nth-child(even) {
          padding-left: 7px;
          .filter-options-content {
            right: 0;
          }
        }
      }
      & > li {
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;
        &.active {
          .filter-content {
            &:after {
              display: block;
              content: "";
              position: absolute;
              height: 10px;
              width: 100%;
              border-width: 0 2px 0 2px;
              border-color: $gray;
              border-style: solid;
              background-color: white;
              z-index: 200;
            }
            .filter-options-content {
              display: block;
            }
            p.filter-options-title {
              border-bottom: none;
            }
          }
        }
        .filter-content {
          position: relative;
          color: $lux-gray;

          p.filter-options-title {
            margin: 0;
            border: 2px solid $gray;
            height: 35px;
            text-transform: uppercase;
            font-size: 13px;
            padding: 8px 10px;
            background-image: url(../images/category/gold-down-arrow.png);
            background-size: 13px;
            background-repeat: no-repeat;
            background-position: right 10px center;
            font-weight: 300;
          }
          .filter-options-content {
            white-space: nowrap;
            padding: 10px 15px;
            display: none;
            position: absolute;
            min-width: 100%;
            top: 43px;
            background-color: white;
            z-index: 100;
            border: 2px solid $gray;

            ul.items {
              list-style: none;
              padding: 0;
              max-height: 300px;
              overflow-y: scroll;
              padding-right: 15px;
              &::-webkit-scrollbar {
                width: 7px;
              }
              &::-webkit-scrollbar-track {
                background-color: $gray;
              }
              &::-webkit-scrollbar-thumb {
                background-color: $gold;
              }
              li.item {
                height: 25px;
                font-weight: 300;
                a {
                  color: $lux-gray;
                  text-decoration: none;
                  height: 100%;
                  display: block;
                  position: relative;
                  &:before {
                    display: inline-block;
                    vertical-align: top;
                    content: "";
                    height: 16px;
                    width: 16px;
                    border: 1px solid $lux-gray;
                    margin-right: 8px;
                  }
                }
              }
              li.m-selected {
                a {
                  &:after {
                    display: block;
                    content: "";
                    height: 10px;
                    width: 10px;
                    background-color: $gold;
                    position: absolute;
                    top: 3px;
                    left: 3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .filter-current {
    display: inline-block;
    ul.items {
      list-style: none;
      padding: 0;
      .item {
        display: inline-block;
        background-color: $super-light-blue;
        color: $light-black;
        padding: 6px 12px;
        margin-bottom: 10px;
        margin-right: 7px;
        .action.remove {
          position: relative;
          width: 16px;
          height: 16px;
          display: inline-block;
          opacity: 0.3;
          vertical-align: middle;
          margin-left: 5px;
          &:hover {
            opacity: 1;
          }
          &:before, &:after {
            position: absolute;
            content: ' ';
            height: 15px;
            width: 1px;
            left: 7px;
            background-color: $gold;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  .filter-actions {
    float: right;
    .action {
      display: block;
      background-color: $super-light-blue;
      color: $light-black;
      padding: 6px 12px;
      text-decoration: none;
      margin-bottom: 10px;
      &:hover {
        .cross {
          opacity: 1;
        }
      }
      .cross {
        position: relative;
        width: 16px;
        height: 16px;
        display: inline-block;
        opacity: 0.3;
        vertical-align: middle;
        margin-left: 5px;

        &:before, &:after {
          position: absolute;
          content: ' ';
          height: 15px;
          width: 1px;
          left: 7px;
          background-color: $gold;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .price-range-filter {
    #amount {
      border: none;
      font-size: 12px;
    }
    #price-range {
      background-color: $gray;
      .ui-slider-handle {
        background-color: $gold;
      }
    }
  }
}