div.toolbar.toolbar-products {
  margin-bottom: 8px;
  margin-top: 17px;

  color: $light-black;
  & > .toolbar-container {
    display: table;
    padding-top: 7px;
    padding-bottom: 7px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
    label {
      display: table-cell;
      padding: 6px 0;
      width: 50%;
    }
    div.field.limiter {
      display: table-row;

      div.control {
        display: table-cell;
        padding: 7px 0;
        width: 50%;

        select {
          margin-right: 8px;
        }
      }
    }

    div.toolbar-sorter.sorter {
      display: table-row;
      div.control {
        padding: 7px 0;
        display: table-cell;
        width: 50%;
        select {
          width: 100%;
        }
      }
    }
  }
}