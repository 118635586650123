.product-base-view{
  margin: 12px auto 30px auto;
}
.product-info-info {
  .product-info-stock-sku {
    border-bottom: 1px solid $gray;
    #product-name {
      font-weight: 500;
      font-size: 27px;
    }
    .product-manufacturer {
      font-size: 15px;
      margin: 10px 0 5px 0;
      text-transform: uppercase;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
    .product-squ {
      color: $dark-gray;
      font-weight: 100;
      font-size: 14px;
      margin: 0 0 15px 0;
    }
  }
  .product-item-download {
    margin-top: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: $dark-gray;

    .fa-download {
      color: $custom-green;
      font-size: 13px;
      margin-right: 10px;
    }
  }

  .product-reviews-summary {
    font-size: 20px;
     margin: 15px 0;
    .review-stars-gray {
      color: $gray;
      position: relative;
      display: inline-block;

      .review-stars-color {
        color: #f2a53c;
        position: absolute;
        top: 0;
        overflow: hidden;
      }
    }
    .review-count {
      font-size: 0.8em;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}
.product-info-main {
  .price-box-wrapper {
    margin-top: 20px;
    position: relative;

    .instalment {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      font-size: 11px;
      color: $light-black;
    }
    .price-box {
      display: block;
      font-size: 25px;
      font-weight: 500;

      .price.currency {
        font-size: 0.75em;
      }
      .price.after-comma {
        font-size: 0.75em;
      }
      .special-price {
        color: $lux-red;
        display: block;
      }
      .old-price {
        font-size: 11px;
        display: block;
        margin: 0;
        .instalment {
          display: none;
        }
        .price-wrapper {
          color: $light-black;
          .price {
            vertical-align: initial;
            text-decoration: line-through;
          }
        }
      }
    }
    p.our-price {
      color: $dark-gray;
      font-weight: bold;
      text-transform: uppercase;
      font-style: normal;
      font-size: 14px;
      margin-bottom: 5px;
    }
    p.price-tax {
      margin-bottom: 5px;
      font-weight: 100;
      color: $lux-gray;
      font-size: 11px;
    }
    .price-details {
      font-size: 11px;
      margin-bottom: 12px;
      .save {
        float: left;
        color: $lux-red;
        display: inline-block;
      }
      .availability {
        float: right;
        color: $lux-green;
        display: inline-block;
      }
      &:after {
        content: "";
        clear: both;
        display: block;
      }
    }
  }
  .product-add-form {
    font-size: 0;
    margin: 0 0 15px 0;
    display: block;
    .fieldset {
      height: 45px;
      .field.qty {
        display: inline-block;
        width: 40%;
        padding-right: 10px;
        height: 100%;
        vertical-align: top;
        .qty-box {
          display: inline-block;
          max-width: 100%;
          min-width: 100px;
          height: 100%;
          background-color: $lighter-gray;
          color: $ss-dark-gray;
          font-size: 0;
          > * {
            display: inline-block;
            font-size: 30px;
            width: 33%;
            height: 100%;
            text-align: center;
            vertical-align: middle;
            padding: 5px;
          }
          .qty-plus:hover,
          .qty-minus:hover {
            cursor: pointer;
          }
          .input-text.qty.qty-center {
            border: none;
            background-color: transparent;
            text-align: center;
            -moz-appearance: textfield;
          }
          .input-text.qty::-webkit-inner-spin-button,
          .input-text.qty::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
      .actions {
        display: inline-block;
        width: 100%;
        height: 100%;
        vertical-align: top;
      }
      button.tocart {
        width: 100%;
        text-transform: uppercase;
        font-size: 14px;
        height: 100%;
      }
    }
  }
  .product-info {
    border-bottom: 1px solid $gray;
    padding-bottom: 10px;
    [data-type="cms-block"] {
      cursor: pointer;
    }
    table {
      font-weight: 300;
      i {
        color: $gold;
        font-size: 14px;
      }
      td:first-child {
        padding: 4px 15px 4px 0;
        color: $lux-gray;
      }
      [data-value="kostenlos"]{
        color: $lux-green;
      }
    }
  }
  .payment-buttons {
    margin-bottom: 10px;
    & > div {
      a {
        display: block;
        text-align: center;
        height: 45px;
        padding: 10px 0 0 0;
        text-decoration: none;
        i {
          font-size: 18px;
          vertical-align: middle;
        }
        span {
          color: $light-black;
          line-height: 18px;
          vertical-align: middle;
        }
      }
    }
    & > div:first-child {
      padding-right: 3px;
    }
    & > div:last-child {
      padding-left: 3px;
    }
  }
  .shipping-icons {
    & > div {
      padding: 0;
      display: inline-block;
      &:not(:last-child) {
        margin-right: 27px;
      }
      & > div {
        vertical-align: top;
        zoom:0.5;
        -moz-transform:scale(0.5);
        -moz-transform-origin: 0 0;
      }
      &:not(:last-child) {
        width: 80px;
        height: 35px;
      }
      &:last-child {
        width: 105px;
        height: 35px;
      }
    }
  }
}
.product.media {
  min-height: 300px;
}
.product-payment-box{
  display: block;
  width: 100%;
}

.gallery-with-badge-placeholder{
  position: relative;
  .badge{
    text-align: center;
    top:5px;
    right: 10px;
  }
}

#sliders-switcher-product {
  .slider-header {
    text-transform: uppercase;
    text-align: center;
    padding: 50px 0;
    margin: 0;
    font-size: 20px;
    color: $light-black;
    font-weight: 300;
  }
}