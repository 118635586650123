.page-footer {
  margin-top: 20px;
  background-color: $light-black;
  background-image: url('/pub/static/frontend/Madcoders/luxustrade/en_US/images/footer/black-pattern.png');
  background-size: 40px 23px;

  .title {
    color: white;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .mobile-links {
    > ul {
      list-style-type: none;
      display: inline-block;
      width: 100%;
      margin: 0;
      padding: 0;
      text-align: center;
      text-transform: uppercase;
      > li {
        padding: 11px 0;
        @include links(white, false); // helpers/_functions.scss
        font-weight: 600;
        &:nth-child(even) {
          background-color: $light-black;
        }
        &:nth-child(odd) {
          background-color: $black;
        }
      }
    }
  }

  .social-media {
    padding: 25px 0;
    text-align: center;
    height: 100%;
    > ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: inline-block;
      @include social-icons(white, $black, $gold, false); // helpers/functions.scss
    }
  }

  .block.newsletter {
    padding-top: 20px;
    padding-bottom: 20px;

    .field.newsletter {
      margin-right: -79px;
      padding-right: 74px;
      display: inline-block;
      width: 100%;
      vertical-align: top;

      input[type="email"] {
        width: 100%;
        height: 45px;
        padding: 0 10px;
        border: none;
        line-height: 45px;

        &:focus {
          outline: none;
        }
      }
    }
    .actions {
      display: inline-block;
      vertical-align: top;

      button.subscribe {
        @extend .btn-gold-grad;
        height: 45px;
        width: 75px;
        background: $gold;
        color: white;
        border: none;
        &:focus {
          outline: none;
        }
      }

    }
  }
}