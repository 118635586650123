.breadcrumbs-container {
  margin-top: 18px;
  padding: 0 15px;
}

.breadcrumbs {
  .previous-page {
    font-size: 11px;
    font-weight: 300;
    a {
      color: $gold;
      margin-right: 15px;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: $lux-gray;
      }
      &:before {
        content: "\f104";
        font-family: FontAwesome;
        font-size: 16px;
        vertical-align: text-bottom;
        font-weight: 300;
        margin-right: 5px;
      }
    }
  }
  .items {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    display: inline-block;
    .item {
      display: inline-block;
      color: $lux-gray;
      font-size: 11px;
      font-weight: 300;
      &:not(:last-child):after {
        content: '/';
        margin: 0 10px;
      }
      &:last-child {
        strong {
          font-weight: normal;
        }
      }
      > a {
        color: $lux-gray;
        text-decoration: none;
        &:hover {
          text-decoration: none;
          color: $gold;
        }
      }
    }
  }
}

.checkout-cart-index{
  .breadcrumbs-container{
    display: none;
  }
}

.customer-account-login,
.checkout-cart-index,
.customer-account-forgotpassword,
.customer-account-create,
.cms-page-view,
.checkout-index-index,
.checkout-onepage-success {
  .breadcrumbs {
    position: relative;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    max-width: 1170px;
    padding-top: 12px;
    padding-left: 15px;
    z-index: 1;

    .previous-page {
      display: none;
    }

    .item {
      font-size: 12px;
    }
  }

  @media (min-width: 1600px) {
    .breadcrumbs {
      max-width: 1600px;
    }
  }
  @media (min-width: 1900px) {
    .breadcrumbs {
      max-width: 1900px;
    }
  }
  @media (min-width: 768px) {
    .breadcrumbs {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .breadcrumbs {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .breadcrumbs {
      width: 1180px;
    }
  }
}