.banner-slider-content {
  margin: 0 0 30px 0;
  .item {
    .banner {
      &-content {
        width: 100%;
        height: $banner-m-height;
        position: relative;
        background-repeat: no-repeat;
        left: 0;
        top: 0;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        font-size: 0;
        padding: 5px 0;
      }
      &-container {
        height: 100%;
      }

      //left content
      &-left-content {
        width: 35%;
        display: inline-block;
        height: 100%;
        position: relative;
        vertical-align: middle;
        padding-right: 20px;
        text-align: right;
      }

      &-image {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        position: absolute;
        right: 0;
        top: 50%;
        @include transform-center($transform-center-vertical);
      }

      //right content
      &-right-content {
        width: 65%;
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;
        text-align: left;
      }

      &-center-content {
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        padding-top: 30px;
      }

      &-title {
        font-size: 27px;
        color: #FFFFFF;
        font-weight: bold;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.35);
      }

      &-description {
        font-size: 9px;
        color: #FFFFFF;
        letter-spacing: 3px;
        font-weight: 500;
        position: relative;
        top: 6px;
      }

      &-btn {
        padding: 7px 10px 5px 10px;
        background-color: $gold;
        color: #FFFFFF;
        display: inline-block;
        font-size: 11px;
        margin-top: 5px;
        @extend .btn-gold-grad;
      }

      &-btn:hover {
        cursor: pointer;
      }
    }
  }
  .owl-controls {
    width: 100%;
    position: absolute;
    top: 55px;
    .owl-nav {
      .owl-prev,
      .owl-next {
        position: absolute;
        display: inline-block !important;
        .nav-box {
          //background-color: rgba(0, 0, 0, 0.5);
          color: #FFFFFF;
          width: 30px;
          height: 35px;
          text-align: center;
          position: relative;
          > * {
            display: inline-block;
            margin-top: 10px;
          }
        }
      }
      .owl-prev {
        left: 0;
      }
      .owl-next {
        right: 0;
      }
    }
    .owl-dots {
      text-align: center;
      position: absolute;
      top: 70px;
      width: 100%;
      display: inline-block !important;
      max-height: 12px;
      .owl-dot {
        width: 15px;
        height: 2px;
        //border-radius: 50%;
        background-color: $lux-gray;
        display: inline-block;
        margin-right: 10px;
        border-radius: 0;
      }
      .owl-dot.active {
        background-color: $gold;
      }
    }
  }
}