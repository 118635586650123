div.blog-cat-header {
  .breadcrumbs {
    @extend %custom-container;
    position: relative;
    top: -35px;
    padding: 0 15px;
  }

  div.blog-category-description {
    color: white;
    min-height: 125px;
    background-image: url(../images/category/desk.png);
    background-size: cover;
    background-position: center center;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    .description-content {
      font-size: 13px;
      overflow: hidden;
      height: 72px;
      p {
        margin:0;
      }
      .manufacturer-logo {
        text-align: center;
        margin-bottom: 20px;
        img {
          max-height: 72px;
          max-width: 100%;
        }
      }
    }

    .container {
      z-index: 2;
      position: relative;
    }
    a.weiter-lesen {
      font-size: 14px;
      color: white;
      text-decoration: underline;
      display: none;
      margin-top: 17px;
    }
    h1 {
      font-family: 'Playfair Display', serif;
      font-size: 18px;
      font-weight: bold;
      margin: 25px 0 17px;
    }

  }
}