.mad-billing-address {
  display: none;
  padding: 0 30px 20px 30px;

  .validation-advice {
    position: relative;
    margin-bottom: 10px;

    &:before {
      top: -5px !important;
    }

    &:after {
      top: 3px !important;
    }
  }

  .label:not([for='mad-billing-company']):after {
    @extend %red-star-after-label;
  }
}