@media only screen and (max-width: 767px) {
  .account {

    .block-dashboard-orders {

      .block-title {
        .action {
          float: right;
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }

    .block-dashboard-info {
      margin-bottom: 40px;

      .box-information {
        .box-actions {
          .action {
            &:first-child:after {
              content: '';
              display: inline-block;
              height: 12px;
              margin: 0 10px;
              border-left: 1px solid #a6a6a6;
              vertical-align: -1px;
            }
          }
        }
      }

      .box-newsletter {
        margin-bottom: 0;
      }
    }

    .block-dashboard-addresses {
      margin-bottom: 0;

      .block-title {
        .action {
          float: right;
          margin-top: 5px;
          font-size: 14px;
        }
      }
    }

    .block-addresses-list {
      margin-bottom: 50px;

      .block-content {

        .items.addresses {
          .item:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .item.actions {
          .action {
            &:first-child:after {
              content: '';
              display: inline-block;
              height: 12px;
              margin: 0 10px;
              border-left: 1px solid #a6a6a6;
              vertical-align: -1px;
            }
          }
        }
      }
    }

  }
}