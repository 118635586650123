.blog-post {
  margin-top: 20px;
  font-size: 14px;

  .social-button {
    top: -3px;
    position: relative;
    font-size: 20px;
    line-height: 1.0;
  }
  .blog-post__share-content {
    .social-button {
      a {
        padding: 0 7px;
      }
    }
  }

  header {
    .blog-post__title {
      font-family: 'Playfair Display', serif;
      color: $light-black;
      margin-bottom: 10px;
      font-size: 22px;
      font-weight: 300;
      *{
        text-transform: none;
      }
      a {
        text-decoration: none;
      }
    }
  }
  ul.blog-post__info {
    margin: 0 0 15px 0;
    padding: 0;
    li {
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 2px;

      a:hover {
        color: $gold;
      }

      .social-button {
        display: inline;
      }

      &:not(:last-child) {
        border-right: 1px solid $gray;
        padding-right: 10px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
    .blog-post__info-item {
      a {
        text-decoration: none;
      }
    }
  }
  .blog-post__content {
    margin-bottom: 20px;
    font-size: 0;
    border-bottom: 1px solid $gray;
    padding-bottom: 30px;
    a {
      text-decoration: underline;
    }
    .blog-post__read-more-wrapper {
      a {
        text-decoration: none;
      }
    }
    * {
      color: $ss-gray;
      font-size: 14px;
    }
    h2 {
      font-weight: 500;
      //color: #ed1c24;
      line-height: 1.2;
      font-size: 19px;
    }
    h3 {
      font-weight: 500;
      //color: #ed1c24;
      line-height: 1.2;
      font-size: 16px;
    }
    .row{
      font-size: 0;
    }

    .col-l,
    .col-r{
      width:100%;
      display: inline-block;
      vertical-align: top;

      p {
        font-size: 12px;
        line-height: 2;
      }

      .blog-post__read-more-wrapper {
        margin-top: 10px;
        .blog-post__read-more {
          padding: 7px 0;
          font-size: 12px;
          color: $gold;
          text-transform: uppercase;
          letter-spacing: 1px;

          &:after {
            font-family: FontAwesome;
            content: '\f105';
            margin-left: 5px;
            color: $gray;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .blog-post__content-image {
      max-width: 100%;
    }
    .product__short-description {
      .image-href {
        display: block;
        text-align: center;
        img {
          max-width: 100%;
        }
      }
    }
  }

  .blog-post__author {
    background-color: $lighter-gray;
    border-bottom: 1px solid $gray;
    padding: 14px;
    margin-bottom: 10px;
  }
  .blog-post__tags {
    margin-bottom: 30px;

    .blog-tags__tag {
      display: inline-block;
      background-color: $lighter-gray;
      color: $dark-gray;
      line-height: 24px;
      padding: 5px 14px;
      margin-right: 4px;
      margin-bottom: 6px;
      text-decoration: none;
      &:hover {
        background-color: $gray;
        text-decoration: none;
      }
    }
  }

  .blog-post__share {
    margin-bottom: 30px;
    .blog-post__share-title {
      display: inline;
    }
    .blog-post__share-content {
      display: inline-block;
      background-color: $lighter-gray;
      padding: 3px 14px 0px 14px;
      margin-left: 10px;

      .social-button {
        display: inline;
        top: 0;

        a {
          color: $gold;
        }
      }
    }
  }
}
.blog-post-index {
  .blog-post {
    .blog-post__content {
      .blog-post__content-image {
        max-width: 40%;
        float: right;
        margin: 0 0 20px 30px;
      }
      img {
        margin: 10px 20px 10px 0;
      }
    }
  }
}