.checkout-index-index {
  .validation-advice {
    display: none;
    height: 30px;
    background: $lux-red;
    width: 100%;
    border: none;
    color: white;
    padding: 9px 0 0 40px;
    margin-top: 10px;
    font-size: 11px;
    white-space: nowrap;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 5px 8px;
      border-color: transparent transparent $lux-red;
      position: absolute;
      top: 40px;
      left: 15px;
      font-family: "FontAwesome", sans-serif;
    }

    &:after {
      content: '\f06a';
      width: 0;
      height: 30px;
      font-family: "FontAwesome", sans-serif;
      left: 16px;
      position: absolute;
      font-size: 20px;
      top: 48px;
      line-height: 25px;
    }
  }
}