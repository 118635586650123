header {
  color: $lux-gray;
  font-size: 10px;
  font-weight: 300;
  a {
    color: inherit;
  }
  a:hover {
    text-decoration: none;
  }

  .financing-info {
    display: none;
  }

  .logo {
    img {
      height: 56px;
    }
  }
  div.panel.header {
    margin: 0;
    #top-info-contact {
      background-color: $light-black;
      color: #FFFFFF;
      text-align: center;
      line-height: 2em;
      font-size: 14px;
      .icon {
        position: relative;
        top: -2px;
        margin-right: 5px;
        font-size: 18px;
        vertical-align: middle;
      }
    }
    #top-info {
      display: none;
    }
  }
  div.header.content {
    text-align: center;
    padding-bottom: 20px;
    position: relative;
    div.mobile-nav {
      float: left;
      display: inline-block;
      margin-top: 18px;
      p.bar {
        background-color: $dark-gray;
        height: 3px;
        width: 25px;
        margin-bottom: 5px;
      }
    }
    div.links {
      display: block;
      float: right;
      vertical-align: middle;
      margin-top: 14px;
      & > div {
        display: none;
        vertical-align: middle;
        &.block-search {
          display: inline-block;
        }
      }
      .minicart-wrapper{
        display: inline-block;
      }
      .account-link {
        display: none;
        margin-right: 10px;
        a {
          display: block;
          div.img {
            width: 25px;
            height: 25px;
            margin: 0 auto;
            background-image: url(../images/header/user-icon.png);
            background-size: cover;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  #wiesbaden-info {
    display: block;
    padding: 7px 0;
    line-height: 1em;
    background-color: $super-light-blue;
    .marker {
      i.fa-map-marker {
        font-size: 14px;
        vertical-align: middle;
        position: relative;
        bottom: 1px;
        margin-right: 4px;
      }
      span {
        vertical-align: middle;
      }
      text-align: center;
      a, a:hover {
        color: $light-black;
      }
    }
    ul {
      padding: 0;
      li {
        list-style: none;
        display: inline-block;
        i {
          margin-right: 5px;
          color: black;
        }
        a {
          text-transform: none;
        }
        &:not(:last-child) {
          margin-right: 30px;
        }
      }

    }
  }
}

.sticky-logo {
  img {
    width: auto !important;
    height: 50px !important;
    margin-top: 10px;
    transition: all .5s ease;
  }
}

.container {
  #home-page-title,
  #home-page-text {
    text-align: center;
    margin-bottom: 20px;
    line-height: 1.42857;
  }

  #home-page-title {
    font-weight: 300;
    font-size: 20px;
    color: #2d2e2f;
  }

  #home-page-text {
    display: block;
    font-size: 14px;
    color: #565656;
    font-weight: lighter;
  }
}